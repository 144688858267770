@font-face {font-family: 'Glyphicons Halflings';src: url('fonts/glyphicons-halflings-regular.eot');src: url('fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'), url('fonts/glyphicons-halflings-regular.woff') format('woff'), url('fonts/glyphicons-halflings-regular.ttf') format('truetype'), url('fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');}

table.dataTable {
    clear: both;
    max-width: none !important;
    border-collapse: separate !important;
}

.dataTables_length{ margin-bottom:20px; }

table.dataTable td,
table.dataTable th {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

table.dataTable td.dataTables_empty,
table.dataTable th.dataTables_empty {
    text-align: center;
}

table.dataTable.nowrap th,
table.dataTable.nowrap td {
    white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_length label {
    font-weight: normal;
    text-align: left;
    white-space: nowrap;
    display:inline-block;
    width:auto;
}

div.dataTables_wrapper div.dataTables_length select {
    width: 75px;
    display: inline-block;
}

div.dataTables_wrapper div.dataTables_filter {
    text-align: right;
}

div.dataTables_wrapper div.dataTables_filter label {
    font-weight: normal;
    white-space: nowrap;
    text-align: left;
    width:auto;
    display:inline-block;
}

div.dataTables_wrapper div.dataTables_filter input {
    margin-left: 0.5em;
    display: inline-block;
    width: auto;
}

div.dataTables_wrapper div.dataTables_info {
    padding-top: 8px;
    white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_paginate {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    margin: 10px 0 0 0;
    white-space: nowrap;
    font-size:0.8em;
}

div.dataTables_wrapper div.dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    margin-left: -100px;
    margin-top: -26px;
    text-align: center;
    padding: 1em 0;
}

table.dataTable thead>tr>th.sorting_asc,
table.dataTable thead>tr>th.sorting_desc,
table.dataTable thead>tr>th.sorting,
table.dataTable thead>tr>td.sorting_asc,
table.dataTable thead>tr>td.sorting_desc,
table.dataTable thead>tr>td.sorting {
    padding-right: 30px;
}

table.dataTable thead>tr>th:active,
table.dataTable thead>tr>td:active {
    outline: none;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
    cursor: pointer;
    position: relative;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
    position: absolute;
    bottom: 8px;
    right: 8px;
    display: block;
    font-family: 'Glyphicons Halflings';
    opacity: 0.5;
}

table.dataTable thead .sorting:after {
    opacity: 0.2;
    content: "\e150";
}

table.dataTable thead .sorting_asc:after {
    content: "\e155";
}

table.dataTable thead .sorting_desc:after {
    content: "\e156";
}

table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
    color: #eee;
}

div.dataTables_scrollHead table.dataTable {
    margin-bottom: 0 !important;
}

div.dataTables_scrollBody table {
    border-top: none;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

div.dataTables_scrollBody table thead .sorting:after,
div.dataTables_scrollBody table thead .sorting_asc:after,
div.dataTables_scrollBody table thead .sorting_desc:after {
    display: none;
}

div.dataTables_scrollBody table tbody tr:first-child th,
div.dataTables_scrollBody table tbody tr:first-child td {
    border-top: none;
}

div.dataTables_scrollFoot table {
    margin-top: 0 !important;
    border-top: none;
}

table.dataTable tbody tr.highlight.odd td {background-color:#FFDDDD !important;}
table.dataTable tbody tr.highlight.even td {background-color:#FFEEEE !important;}

@media screen and (max-width: 767px) {
    div.dataTables_wrapper div.dataTables_length,
    div.dataTables_wrapper div.dataTables_filter,
    div.dataTables_wrapper div.dataTables_info,
    div.dataTables_wrapper div.dataTables_paginate {
        text-align: center;
    }
}

table.dataTable.table-condensed>thead>tr>th {
    padding-right: 20px;
}

table.dataTable.table-condensed .sorting:after,
table.dataTable.table-condensed .sorting_asc:after,
table.dataTable.table-condensed .sorting_desc:after {
    top: 6px;
    right: 6px;
}

table.table-bordered.dataTable th,
table.table-bordered.dataTable td {
    border-left-width: 0;
}

table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable td:last-child,
table.table-bordered.dataTable td:last-child {
    border-right-width: 0;
}

table.table-bordered.dataTable tbody th,
table.table-bordered.dataTable tbody td {
    border-bottom-width: 0;
    vertical-align:middle;
}

div.dataTables_scrollHead table.table-bordered {
    border-bottom-width: 0;
}

div.table-responsive>div.dataTables_wrapper>div.row {
    margin: 0;
}

div.table-responsive>div.dataTables_wrapper>div.row>div[class^="dw-"]:first-child {
    padding-left: 0;
}

div.table-responsive>div.dataTables_wrapper>div.row>div[class^="dw-"]:last-child {
    padding-right: 0;
}

table.dataTable.dtr-inline.collapsed>tbody>tr>td.child,
table.dataTable.dtr-inline.collapsed>tbody>tr>th.child,
table.dataTable.dtr-inline.collapsed>tbody>tr>td.dataTables_empty {
    cursor: default !important;
}

table.dataTable.dtr-inline.collapsed>tbody>tr>td.child:before,
table.dataTable.dtr-inline.collapsed>tbody>tr>th.child:before,
table.dataTable.dtr-inline.collapsed>tbody>tr>td.dataTables_empty:before {
    display: none !important;
}

table.dataTable.dtr-inline.collapsed>tbody>tr>td:first-child,
table.dataTable.dtr-inline.collapsed>tbody>tr>th:first-child {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
}

table.dataTable.dtr-inline.collapsed>tbody>tr>td:first-child:before,
table.dataTable.dtr-inline.collapsed>tbody>tr>th:first-child:before {
    top: 50%;
    -ms-transform:translate(0,-50%);
    -o-transform:translate(0,-50%);
    -moz-transform:translate(0,-50%);
    -webkit-transform:translate(0,-50%);
    transform:translate(0,-50%);
    left: 4px;
    height: 14px;
    width: 14px;
    display: block;
    position: absolute;
    color: white;
    border: 2px solid white;
    border-radius: 14px;
    box-shadow: 0 0 3px #444;
    box-sizing: content-box;
    text-align: center;
    font-family: 'Courier New', Courier, monospace;
    line-height: 16px;
    content: '+';
    background-color: #e3000f;
}

table.dataTable.dtr-inline.collapsed>tbody>tr.parent>td:first-child:before,
table.dataTable.dtr-inline.collapsed>tbody>tr.parent>th:first-child:before {
    content: '-';
    background-color: #d33333;
}

table.dataTable.dtr-inline.collapsed>tbody>tr.child td:before {
    display: none;
}

table.dataTable.dtr-inline.collapsed.compact>tbody>tr>td:first-child,
table.dataTable.dtr-inline.collapsed.compact>tbody>tr>th:first-child {
    padding-left: 27px;
}

table.dataTable.dtr-inline.collapsed.compact>tbody>tr>td:first-child:before,
table.dataTable.dtr-inline.collapsed.compact>tbody>tr>th:first-child:before {
    top: 5px;
    left: 4px;
    height: 14px;
    width: 14px;
    border-radius: 14px;
    line-height: 14px;
    text-indent: 3px;
}

table.dataTable.dtr-column>tbody>tr>td.control,
table.dataTable.dtr-column>tbody>tr>th.control {
    position: relative;
    cursor: pointer;
}

table.dataTable.dtr-column>tbody>tr>td.control:before,
table.dataTable.dtr-column>tbody>tr>th.control:before {
    top: 50%;
    left: 50%;
    height: 16px;
    width: 16px;
    margin-top: -10px;
    margin-left: -10px;
    display: block;
    position: absolute;
    color: white;
    border: 2px solid white;
    border-radius: 14px;
    box-shadow: 0 0 3px #444;
    box-sizing: content-box;
    text-align: center;
    font-family: 'Courier New', Courier, monospace;
    line-height: 14px;
    content: '+';
    background-color: #e3000f;
}

table.dataTable.dtr-column>tbody>tr.parent td.control:before,
table.dataTable.dtr-column>tbody>tr.parent th.control:before {
    content: '-';
    background-color: #d33333;
}

table.dataTable>tbody>tr.child {
    padding: 0.5em 1em;
}

table.dataTable>tbody>tr.child:hover {
    background: transparent !important;
}

table.dataTable>tbody>tr.child ul.dtr-details {
    display: inline-block;
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
}

table.dataTable>tbody>tr.child ul.dtr-details li {
    border-bottom: 1px solid #efefef;
    padding: 0.5em 0;
}

table.dataTable>tbody>tr.child ul.dtr-details li:first-child {
    padding-top: 0;
}

table.dataTable>tbody>tr.child ul.dtr-details li:last-child {
    border-bottom: none;
}

table.dataTable>tbody>tr.child span.dtr-title {
    display: block;
    min-width: 75px;
    font-weight: bold;
}

div.dtr-modal {
    position: fixed;
    box-sizing: border-box;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 100;
    padding: 10em 1em;
}

div.dtr-modal div.dtr-modal-display {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 50%;
    height: 50%;
    overflow: auto;
    margin: auto;
    z-index: 102;
    overflow: auto;
    background-color: #f5f5f7;
    border: 1px solid black;
    border-radius: 0.5em;
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.6);
}

div.dtr-modal div.dtr-modal-content {
    position: relative;
    padding: 1em;
}

div.dtr-modal div.dtr-modal-close {
    position: absolute;
    top: 6px;
    right: 6px;
    width: 22px;
    height: 22px;
    border: 1px solid #eaeaea;
    background-color: #f9f9f9;
    text-align: center;
    border-radius: 3px;
    cursor: pointer;
    z-index: 12;
}

div.dtr-modal div.dtr-modal-close:hover {
    background-color: #eaeaea;
}

div.dtr-modal div.dtr-modal-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 101;
    background: rgba(0, 0, 0, 0.6);
}

@media screen and (max-width: 767px) {
    div.dtr-modal div.dtr-modal-display {
        width: 95%;
    }
}

div.dtr-bs-modal table.table tr:first-child td {
    border-top: none;
}

.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.form-control:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
}


select.input-sm {
    height: 30px;
    line-height: 30px;
}

.input-sm {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 20px 0;
    border-radius: 4px;
}

.pagination>li {
    display: inline;
}

.pagination>li>a,
.pagination>li>span {
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: #e3000f;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #ddd;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
    margin-left: 0;
}

.pagination>li>a:focus,
.pagination>li>a:hover,
.pagination>li>span:focus,
.pagination>li>span:hover {
    z-index: 2;
    color: #e3000f;
    background-color: #eee;
    border-color: #ddd;
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
    z-index: 3;
    color: #fff;
    cursor: default;
    background-color: #333;
    border-color: #333;
}

.pagination>.disabled>a,
.pagination>.disabled>a:focus,
.pagination>.disabled>a:hover,
.pagination>.disabled>span,
.pagination>.disabled>span:focus,
.pagination>.disabled>span:hover {
    color: #777;
    cursor: not-allowed;
    background-color: #fff;
    border-color: #ddd;
}

.pagination-lg>li>a,
.pagination-lg>li>span {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
}

.pagination-lg>li:first-child>a,
.pagination-lg>li:first-child>span {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.pagination-lg>li:last-child>a,
.pagination-lg>li:last-child>span {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.pagination-sm>li>a,
.pagination-sm>li>span {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
}

.pagination-sm>li:first-child>a,
.pagination-sm>li:first-child>span {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.pagination-sm>li:last-child>a,
.pagination-sm>li:last-child>span {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}


.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ddd;
}

.table>thead>tr>th {
    vertical-align: bottom;
    border-bottom: 2px solid #ddd;
    font-weight:bold;
    color: #333;
}

.table>caption+thead>tr:first-child>td,
.table>caption+thead>tr:first-child>th,
.table>colgroup+thead>tr:first-child>td,
.table>colgroup+thead>tr:first-child>th,
.table>thead:first-child>tr:first-child>td,
.table>thead:first-child>tr:first-child>th {
    border-top: 0;
}

.table>tbody+tbody {
    border-top: 2px solid #ddd;
}

.table .table {
    background-color: #fff;
}

.table-condensed>tbody>tr>td,
.table-condensed>tbody>tr>th,
.table-condensed>tfoot>tr>td,
.table-condensed>tfoot>tr>th,
.table-condensed>thead>tr>td,
.table-condensed>thead>tr>th {
    padding: 5px
}

.table-bordered {
    border: 1px solid #ddd;
}

.table-bordered>tbody>tr>td,
.table-bordered>tbody>tr>th,
.table-bordered>tfoot>tr>td,
.table-bordered>tfoot>tr>th,
.table-bordered>thead>tr>td,
.table-bordered>thead>tr>th {
    border: 1px solid #ddd;
}

.table-bordered>thead>tr>td,
.table-bordered>thead>tr>th {
    border-bottom: 0px !important;
    background-color:#fff;
}

.table-striped>tbody>tr {
    background-color: #fff;
}

.table-striped>tbody>tr:nth-of-type(odd) {
    background-color: #f9f9f9;
}

.table-hover>tbody>tr:hover {
    background-color: #f5f5f5;
}

table col[class*=dw-] {
    position: static;
    display: table-column;
    float: none;
}

table td[class*=dw-],
table th[class*=dw-] {
    position: static;
    display: table-cell;
    float: none;
}

.table>tbody>tr.active>td,
.table>tbody>tr.active>th,
.table>tbody>tr>td.active,
.table>tbody>tr>th.active,
.table>tfoot>tr.active>td,
.table>tfoot>tr.active>th,
.table>tfoot>tr>td.active,
.table>tfoot>tr>th.active,
.table>thead>tr.active>td,
.table>thead>tr.active>th,
.table>thead>tr>td.active,
.table>thead>tr>th.active {
    background-color: #f5f5f5;
}

.table-hover>tbody>tr.active:hover>td,
.table-hover>tbody>tr.active:hover>th,
.table-hover>tbody>tr:hover>.active,
.table-hover>tbody>tr>td.active:hover,
.table-hover>tbody>tr>th.active:hover {
    background-color: #e8e8e8;
}

.table>tbody>tr.success>td,
.table>tbody>tr.success>th,
.table>tbody>tr>td.success,
.table>tbody>tr>th.success,
.table>tfoot>tr.success>td,
.table>tfoot>tr.success>th,
.table>tfoot>tr>td.success,
.table>tfoot>tr>th.success,
.table>thead>tr.success>td,
.table>thead>tr.success>th,
.table>thead>tr>td.success,
.table>thead>tr>th.success {
    background-color: #dff0d8;
}

.table-hover>tbody>tr.success:hover>td,
.table-hover>tbody>tr.success:hover>th,
.table-hover>tbody>tr:hover>.success,
.table-hover>tbody>tr>td.success:hover,
.table-hover>tbody>tr>th.success:hover {
    background-color: #d0e9c6;
}

.table>tbody>tr.info>td,
.table>tbody>tr.info>th,
.table>tbody>tr>td.info,
.table>tbody>tr>th.info,
.table>tfoot>tr.info>td,
.table>tfoot>tr.info>th,
.table>tfoot>tr>td.info,
.table>tfoot>tr>th.info,
.table>thead>tr.info>td,
.table>thead>tr.info>th,
.table>thead>tr>td.info,
.table>thead>tr>th.info {
    background-color: #d9edf7;
}

.table-hover>tbody>tr.info:hover>td,
.table-hover>tbody>tr.info:hover>th,
.table-hover>tbody>tr:hover>.info,
.table-hover>tbody>tr>td.info:hover,
.table-hover>tbody>tr>th.info:hover {
    background-color: #c4e3f3;
}

.table>tbody>tr.warning>td,
.table>tbody>tr.warning>th,
.table>tbody>tr>td.warning,
.table>tbody>tr>th.warning,
.table>tfoot>tr.warning>td,
.table>tfoot>tr.warning>th,
.table>tfoot>tr>td.warning,
.table>tfoot>tr>th.warning,
.table>thead>tr.warning>td,
.table>thead>tr.warning>th,
.table>thead>tr>td.warning,
.table>thead>tr>th.warning {
    background-color: #fcf8e3;
}

.table-hover>tbody>tr.warning:hover>td,
.table-hover>tbody>tr.warning:hover>th,
.table-hover>tbody>tr:hover>.warning,
.table-hover>tbody>tr>td.warning:hover,
.table-hover>tbody>tr>th.warning:hover {
    background-color: #faf2cc;
}

.table>tbody>tr.danger>td,
.table>tbody>tr.danger>th,
.table>tbody>tr>td.danger,
.table>tbody>tr>th.danger,
.table>tfoot>tr.danger>td,
.table>tfoot>tr.danger>th,
.table>tfoot>tr>td.danger,
.table>tfoot>tr>th.danger,
.table>thead>tr.danger>td,
.table>thead>tr.danger>th,
.table>thead>tr>td.danger,
.table>thead>tr>th.danger {
    background-color: #f2dede;
}

.table-hover>tbody>tr.danger:hover>td,
.table-hover>tbody>tr.danger:hover>th,
.table-hover>tbody>tr:hover>.danger,
.table-hover>tbody>tr>td.danger:hover,
.table-hover>tbody>tr>th.danger:hover {
    background-color: #ebcccc;
}

.table-responsive {
    min-height: .01%;
    overflow-x: auto;
}

@media screen and (max-width:767px) {
    div.dataTables_wrapper div.dataTables_paginate ul.pagination{ margin-top:10px; }
    .dataTables_filter{ margin-bottom:15px; }
    .table-responsive {
        width: 100%;
        margin-bottom: 15px;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        border: 1px solid #ddd;
    }
    .table-responsive>.table {
        margin-bottom: 0;
    }
    .table-responsive>.table>tbody>tr>td,
    .table-responsive>.table>tbody>tr>th,
    .table-responsive>.table>tfoot>tr>td,
    .table-responsive>.table>tfoot>tr>th,
    .table-responsive>.table>thead>tr>td,
    .table-responsive>.table>thead>tr>th {
        white-space: nowrap;
    }
    .table-responsive>.table-bordered {
        border: 0;
    }
    .table-responsive>.table-bordered>tbody>tr>td:first-child,
    .table-responsive>.table-bordered>tbody>tr>th:first-child,
    .table-responsive>.table-bordered>tfoot>tr>td:first-child,
    .table-responsive>.table-bordered>tfoot>tr>th:first-child,
    .table-responsive>.table-bordered>thead>tr>td:first-child,
    .table-responsive>.table-bordered>thead>tr>th:first-child {
        border-left: 0;
    }
    .table-responsive>.table-bordered>tbody>tr>td:last-child,
    .table-responsive>.table-bordered>tbody>tr>th:last-child,
    .table-responsive>.table-bordered>tfoot>tr>td:last-child,
    .table-responsive>.table-bordered>tfoot>tr>th:last-child,
    .table-responsive>.table-bordered>thead>tr>td:last-child,
    .table-responsive>.table-bordered>thead>tr>th:last-child {
        border-right: 0;
    }
    .table-responsive>.table-bordered>tbody>tr:last-child>td,
    .table-responsive>.table-bordered>tbody>tr:last-child>th,
    .table-responsive>.table-bordered>tfoot>tr:last-child>td,
    .table-responsive>.table-bordered>tfoot>tr:last-child>th {
        border-bottom: 0;
    }
}
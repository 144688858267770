﻿.showroom {
    margin: 45px auto 75px;

    @include media('<=phone') {
        margin: 45px auto 40px;
    }

    h2 {
        color: $color-primary;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 40px;
    }

    h3 {
        color: $color-primary;
        margin: 30px 0 20px;
    }

    img, iframe {
        border-radius: 2px;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.17);
        margin: auto;
        display: block;
    }

    iframe {
        height: 330px;
        max-width: 570px;
        width: 100%;
        @include media('<desktop','>tablet') {
            width: 455px;
            height: 263px;
        }

        @include media('<=tablet') {
            margin-top: 30px;
        }

        @include media('<=phone') {
            width: 100%;
        }
    }
}

.showroom--text {
    color: $color-dark-grey;
    font-size: 0.9em;

    a {
        color: $color-orange;
    }
}

.showroom__surround--bg {
    position: relative;
    background: url('https://i.ytimg.com/vi_webp/rx4DaEAy3jw/sddefault.webp') no-repeat;
    height: 330px;
    max-width: 570px;
    margin: auto;
    background-position: center;

    @include media('<desktop','>tablet') {
        width: 455px;
        height: 263px;
    }

    @include media('<=tablet') {
        margin-top: 30px;
    }

    @include media('<=phone') {
        width: 100%;
    }
}



.showroom__surround--youtube {
    position: absolute;
    top: 50%;
    margin-top: -50px;
    left: 50%;
    transform: translate(-50%);
    line-height: 100px;
    display: block;
    color: rgba(255,9,18,1);
    cursor: pointer;
    font-size: 4.5em;

    &:hover {
        opacity: .8;
        transition: .2s;
    }
}
﻿.hero {
    position: relative;
    background-color: $color-primary;
    border-top: 5px solid $color-second;
}

.hero__logged-in {
    background-color: $color-orange;
    text-align: center;
    color: $color-white;
    font-size: 0.9em;
    padding: 5px;
    font-weight: 600;
    display: none;
}

.hero__company {

    @include media('>tablet') {
        padding: 15px 0;
    }

    @include media('<=tablet') {
        margin-top: 20px;
    }
}

.hero__top--break {
    color: $color-white;
    margin: 0 3px;

    @include media('<=phone') {
        display: none;
    }
}


.hero__logo {
    svg {
        width: 100%;
        height: 100%;
    }

    @include media('>tablet') {
        width: 30%;
    }

    @include media('<=tablet') {
        text-align: center;
        width: 40%;
    }

    @include media('<=phone') {
        width: 46%;
    }
}

.hero__search {
    @include media('<desktop','>tablet') {
        width: 37% !important;
        margin: auto !important;
    }

    @include media('>tablet') {
        width: 36.6%;
        margin: auto 30px;
    }

    @include media('<=tablet') {
        clear: both;
    }
}

.hero--search {
    position: relative;
    margin-top: 20px;
}

.hero__company [type="text"] {
    border: 1px solid $color-white;
    border-radius: 3px;
    height: 45px;
    outline: none;
    padding-right: 50px;
    font-size: 0.9em;

    @include media('<=tablet') {
        margin-bottom: 25px;
    }

    @include primary-font-stack;
}

.hero--magnify {
    background-color: $color-white;
    color: $color-orange;
    outline: none;
    border: 0;
    position: absolute;
    right: 0;
    height: 45px;
    line-height: 48px;
    width: 50px;
    font-size: 1.3em;
    cursor: pointer;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.hero__accounts {
    margin-top: 28px;
    padding: 0 15px;
    float: right;

    @include media('>=desktop') {
        width: 1000px;
        padding-left: 10px;
    }

    @include media('<desktop','>tablet') {
        width: 32.4% !important;
    }

    @include media('<=tablet','>phone') {
        width: 57%;
    }

    @include media('>tablet') {
        width: 28.4%;
    }

    @include media('<=tablet') {
        position: static;
        margin-bottom: 30px;
    }

    @include media('<=phone') {
        margin-top: 15px;
        width: 200px;
    }

    @media screen and (max-width:330px) {
        width: 160px;
    }
}

.account--widgets, .account--cart {
    float: left;
    position: relative;
    display: block;
    width: 50%;
    text-align: center;

    @include media('<=tablet') {
        width: 40%;
    }

    @include media('<=phone') {
        width: 25%;
    }
}

.account--icons {
    margin-right: 10px;
}

.account--telephone {
    a {
        display: none;

        @include media('<=tablet') {
            display: block;
            float: left;
            text-align: center;
            font-size: 1.55em;
            margin-right: 25px;
            margin-top: 5px;
            color: $color-white;
        }

        @include media('<=phone') {
            font-size: 1.15em;
            margin-top: 0;
            margin-right: 20px;
        }
    }

    .num {
        display: none;
    }
}

.account--widgets.browse {
    @include media('<=tablet') {
        width: 7%;
        float: right;
    }

    @include media('<=phone') {
        width: 20%;
    }

    .account--text {
        margin-top: 8px;
    }
}

.account--text {
    @include media('<=phone') {
        display: none !important;
    }
}

.account--text {
    font-size: 0.95em;
    font-weight: 700;
    display: block;
    color: $color-white;
}

.account--small {
    color: $color-white;
    font-size: 0.75em;

    a {
        color: $color-white;

        &:hover {
            color: $color-orange;
        }
    }

    @include media('<=phone') {
        a, .account--items {
            display: none;
        }
    }

    @include media('<=phone') {
        #noofitems {
            display: block;
            position: absolute;
            top: -15px;
            right: 20%;
            background-color: $color-orange;
            color: $color-white;
            width: 20px;
            height: 20px;
            line-height: 20px;
            border-radius: 50%;
            font-size: 0.8em
        }
    }
}

.account--icons {
    display: block;
    text-align: center;
    font-size: 2em;
    color: $color-white;
    min-height: 30px;
    float: left;

    @include media('<=phone') {
        font-size: 1.3em;
    }
}

.show--items {
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: -12px;
    right: 7px;
    background-color: $color-second;
    border-radius: 50%;
    color: $color-white;
    font-size: .9em;
    line-height: 20px;
}

.account--cart {
    &:hover .basket__items, &:hover .triangle {
        visibility: visible;
        display: block;

        @include media('<=phone') {
            visibility: hidden;
            display: none;
        }
    }
}

.basket-hover {
    position: relative;
}

.basket__items {
    position: absolute;
    top: 100%;
    /*z-index: 999;*/
    right: 15px;
    visibility: hidden;
    border: 1px solid $color-white;
    background-color: $color-white;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
    width: 405px;

    h3 {
        color: $color-primary;
        font-weight: normal;
        margin-bottom: 35px;
        text-align: left;
    }
}

.triangle-around {
    position: relative;
    margin-top: 30px;
}

.triangle {
    border-style: solid;
    border-color: transparent $color-white transparent transparent;
    width: 0;
    position: absolute;
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    border-width: 20px 20px 20px 0;
    right: 85%;
    top: -30px;
    z-index: 1;
    visibility: hidden;
}

.triangle:after {
    content: ' ';
    position: absolute;
    border-style: solid;
    border-color: transparent $color-white transparent transparent;
    width: 0;
    border-width: 20px 20px 20px 0;
    top: -20px;
    left: 1.6px;
}

.basket__hover {
    padding: 20px;
}

h3.basket-hover--tprice {
    text-align: center;
    display: block;
}

.basket-hover--image {
    float: left;
    margin-right: 15px;

    img {
        max-width: 80px;
        max-height: 80px;
        border: 1px solid $color-border-lgrey;
        border-radius: 2px;
    }
}

.basket--details {
    text-align: left;
}

.basket--details, .basket--price {
    min-height: 80px;
    padding: 18px 0;
}

.basket-hover--title {
    color: $color-dark;
    font-weight: 600;
    display: block;
}

.basket-hover--price {
    color: $color-primary;
    font-weight: 600;
    text-align: center;
    font-size: 1.2em;
}

.basket-hover--fold {
    margin: 5px 0;
    border: 1px solid $color-border-lgrey;
}

.basket__hover--total {
    border-top: 1px solid $color-white;
    padding: 25px;
}

.basket-hover--total {
    font-weight: 600;
    color: $color-dark;
    font-size: 1.33em;
    float: left;
}

.basket-hover-total--amount {
    display: inline-block;
    float: right;
    color: $color-primary;
    font-weight: 600;
    font-size: 1.83em;
}

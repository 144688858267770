﻿.sitemap {
    font-size: 0.95em;

    li {
        width: 100%;
        font-weight: bold;

        a {
            padding: 0px !important;

            &:hover {
                color: $color-dark;
            }
        }
    }

    .nav__item:nth-child(3n) {
        clear: both;
    }

    .nav__item {
        float: right;

        @include media('>=phone') {
            width: 33%;
            float: left;
        }

        @include media('<=phone') {
            list-style: none;
        }
    }

    .nav__sub {
        visibility: visible;
        position: relative;
        box-shadow: none;
        padding: 0;
        min-height: auto;

        .nav__gift, img {
            display: none;
        }
    }

    .nav__item:last-child .nav__sub--list{
        width: 100%;
    }

    .nav--links {
        color: $color-primary;
        text-align: left;
        font-size: 1em;

        i {
            display: none;
        }

        &:hover {
            color: $color-dark;
        }

        @include media('<=phone') {
            padding: 15px 0 !important;
        }
    }

    .nav-sub--sub {
        &:hover {
            color: $color-primary;
        }
    }
}

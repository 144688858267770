﻿.usps {
    border-bottom: 1px solid $color-second;

    a {
        color: $color-dark;
    }
    .bx-controls-direction a.fa-chevron-right{
        @include media('<tablet','>phone') {
            right:-30px;
        }   
        @include media('<phone') {
            right:15px;
        }  
        @media screen and (max-width:330px) {
            right:0px;
        }
    }
}

.usps, .product__tabs {
    .bx-controls-direction{
        @include media('>tablet') {
                display: none;
            }
    }
    .bx-controls-direction a.fa-chevron-left{
        @include media('<phone') {
            left:0px;
        }   
    }
}

.usps__area {
    float:left;
    padding: 15px 0;

    img {
        vertical-align: middle;
        display: inline-block;
    }

    @include media('<=tablet') {
        text-align: center;
    }

    @include media('<desktop','>tablet') {
        width:242.5px !important;
    }

    @include media('<tablet','>phone') {
        width:375px !important;
    }

    @include media('<phone') {
        width:337px !important;
    }

    @media screen and (max-width:330px) {
        width:300px !important;
    }
}

.product__tabs .usps__area {

    @include media('<desktop','>tablet') {
        width:238px !important;
    }

    @include media('<tablet','>phone') {
        width:365px !important;
    }
}

.usp--around {
    display: inline-block;
    vertical-align: middle;
}

.usp--bold {
    color: $color-primary;
    font-weight: 700;
    text-transform: uppercase;

    @include media('<desktop','>tablet') {
        font-size: 0.8em;
    }
}

.usp--text {
    display: block;
    font-size: 0.9em;
    color: $color-dark;

    @include media('<desktop','>tablet') {
        font-size: 0.75em;
    }
}

.logos {
    margin-bottom: 50px;

    @include media('<=phone') {
        margin-bottom: 25px;
    }


    .dw-md-3 {
        @include media('>tablet') {
            width: 20%;
        }
    }

    .dw-md-3:nth-child(5) {
        @include media('<=tablet') {
            img {
                margin: auto 55%;
            }
        }
    }
}
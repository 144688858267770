﻿.basket {
	position: relative;

	h1 {
		margin-bottom: 20px;
	}

	input[type="text"] {
		border: 1px solid $color-check;
		border-radius: 2px;
		max-width: 80px;
		margin: 0 auto 0;
		display: block;
		color: $color-primary;
		font-size: 1.2em;
		text-align: center;
		padding: 0;
	}

    textarea{
        font-family: "proxima-nova",sans-serif;
        color: $color-dark;
        margin: 15px 15px auto auto;
        min-height:135px;
    }

    #customerquoteform input[type="text"] { text-align:left; font-size: 0.9em; padding:0 10px; color: $color-dark; font-family: "proxima-nova",sans-serif; }

	input[type="submit"] {
		border: none;
		max-width: 130px;
		margin: 0 12px;
		text-transform: uppercase;
		text-align: center;
		color: $color-orange;
		font-weight: 700;
		font-size: .85em;
		text-decoration: underline;
		background: transparent;

		&:hover {
			text-decoration: none;
		}
	}

	.btn--blue, .btn--orange {
		float: left;
		margin: 15px 15px auto auto;
		width: 100%;
		font-weight: 700;
		@include media('>tablet') {
			max-width: 240px;
		}

		@include media('<=phone') {
			margin: 5px 0;
		}
	}
}

.basket__form {
	margin-top: 15px;
}
.basket__around {
	border-left: 1px solid $color-baby-blue;
	border-right: 1px solid $color-baby-blue;
}

.basket__row {
	border-bottom: 1px solid $color-baby-blue;
	padding-bottom: 15px;
	text-align: center;

	@include media('<=phone') {
		border-bottom: none;
	}
}

.basket__row--regular {
	min-height: 95px;
	margin-top: 15px;
	// max 767px
	@include media('<=phone') {
		min-height: 0;
	}

	span.lbl {
		border: 1px solid #dfdfdf;
		border-radius: 2px;
		max-width: 80px;
		margin: 12px auto;
		display: block;
		color: #1262bc;
		font-size: 1.2em;
		text-align: center;
		padding: 0;
	}
}

.basket__row--total {
	@include media('<=phone') {
		border-top: 2px solid $color-dark-grey;
		padding: 10px;
	}
}

.basket__row--main {
	color: $color-primary;
	font-weight: 700;
	background-color: $color-baby-blue;
	padding: 15px;
	border-radius: 2px;
	font-size: 1.3em;

	@include media('<=phone') {
		display: none;
	}
}

.basket__column {
	// min 768px
	@include media('>phone') {
		float: left;
		padding: 0 0 0 10px;
	}
	@include media('<=tablet') {
		font-size: 0.8em;
	}
}

.basket__column--one {
	text-align: left;
	// min 1230px
	@include media('>desktop') {
		width: 55%;
	}

	@include media('<desktop','>tablet') {
		width: 55%;
	}

	@include media('<tablet','>phone') {
		width: 60%;
	}
	// min 991px
	@include media('>tablet') {
		position: relative;
	}

	@include media('<=phone') {
		padding: 0;
		width: 100%;
		min-height: 100px;
		float: none;
		margin-bottom: 15px;
	}

	img {
		border: 1px solid $color-check;
		border-radius: 2px;
	}
}

.basket__column--two {
	// min 1230px
	@include media('>desktop') {
		width: 18%;
	}

	@include media('<desktop','>tablet') {
		width: 18%;
	}

	@include media('<tablet','>phone') {
		width: 15%;
	}

	@include media('<=phone') {
		width: 45%;
		float: left;
		text-align: left;

		input {
			margin: 0 0 0 5px;
			height: 40px;
			line-height: 40px;
			float: left;
		}

		.basket__column--xs {
			float: left;
			line-height: 40px;
		}
	}
}

.basket__column--three, .basket__column--four {
	// min 1230px
	@include media('>desktop') {
		width: 13%;
	}

	@include media('<desktop','>tablet') {
		width: 13%;
	}

	@include media('<tablet','>phone') {
		width: 25%;
	}
}

.basket__column--four {
	@include media('<=phone') {
		width: 25%;
		float: left;
		text-align: right;
		line-height: 40px;
		height: 40px;
	}
}

.basket__column--three {
	@include media('<=phone') {
		width: 25%;
		float: right;
		text-align: right;
		line-height: 40px;
		height: 40px;
	}
}

.basket__column--xs {
	@include media('<=phone') {
		display: inline-block !important;
	}
}

.basket--bold {
	color: $color-orange;
	line-height: 40px;
	font-size: 1.4em;
	font-weight: 700;
}

.basket--bold.sum {
	font-size: 1.2em;
}

.basket--sum {
	text-align: left;
	display: block;
	line-height: 40px;
	padding-left: 15px;
}

.basket--title {
	color: $color-dark;
	text-decoration: underline;
	font-weight: 700;
	font-size: 1.1em;
	display: block;
	// min 768px
	@include media('>phone') {
		display: inline-block;
		margin-top: 10px;
	}
}

.basket--size {
	display: block;
	margin-top: 10px;
}

.basket--remove {
	display: block;
	line-height: 40px;
	font-size: 1.2em;
	margin-top: 10px;
	color: $color-dark;

	&:hover {
		color: $color-orange;
	}
}

.basket__discount {
	background-color: $color-baby-blue;
	cursor: pointer;
	min-height: 50px;
	line-height: 50px;
	padding: 0 15px;
	color: $color-primary;
	font-weight: 700;

	.fa {
		float: right;
		color: $color-orange;
		font-size: 1.4em;
		margin: 14px;
	}
}
.basket__discount--title {
	float: left; 
	width: auto;
}

.basket-discount--slide {
	margin: 15px 0 0 0;

	.shut {
		overflow: hidden;
		height: 0;
	}

	input[type="text"] {
		border: 1px solid #d4d4d4;
		max-width: 100%;
		margin: 15px 15px auto auto;
	}
}

.basket__discount--info.open {
	border: 1px solid $color-baby-blue;

	.discount__text {
		display: block;
	}
}

.basket__edit {
	padding: 15px;
}

.basket--total {
	@include media('<=phone') {
		padding: 0;
		background-color: $color-border-lgrey;
		border-top: 3px solid $color-primary;
		margin-top: 125px;
	}
}

.basket__checkout {
	border: 1px solid $color-baby-blue;
}

.basket--pad {
	padding: 15px;
	background-color: $color-pad;

	.btn {
		margin: 20px auto 0;
		display: block;
		float: none;
	}

	.basket--sum {
		color: $color-dark;
		line-height: 40px;
	}

	.basket--sum.bold {
		font-weight: 700;
	}

	.basket--bold {
		font-size: 1.2em;
	}
}

#collect {
	padding: 25px;

	h2 {
		padding-left: 0;
		font-size: 1.4em;
	}

	.fa-map-marker-alt {
		color: $color-primary;
		font-size: 1.6em;
		margin: 30px 0 25px;
	}

	.address {
		color: $color-dark;
	}

	.btn {
		margin-top: 25px;
		font-weight: 600;
	}
}


.reward--points {
	background-color: $color-tabs;
	padding: 5px;
	margin: 20px 0;

	img {
		margin: 3px;
		display: inline-block;
		vertical-align: middle;
		float: left;
	}

	.r--points {
		margin-left: 15px;
		display: inline-block;
	}
}

.offer--box {
	background-color: #081236;
	color: $color-white;
	text-transform:uppercase;
	font-weight: 600;
	padding: 5px;
	font-size: 0.82em;
	margin: 5px auto;

    &.not{
        background-color: $color-red;
    }
}

.offer--orange {
	color: $color-orange;
}

﻿.carousel {
    position: relative;
    height: 440px;
    margin: 0 auto;

    .item {
        height: 440px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-position: center;

        @include media('>=1900px') {
            background-size: contain;
            background-color: $color-primary;
        }
    }
}

.carousel .item .container {
    position: relative;
    height: 440px;
}

.carousel__inner {
    position: relative;
    width: 100%;
    overflow: hidden;
    z-index: 1;
}

.carousel__caption {
    max-width: 670px;

    @include media('<=tablet') {
        margin-left: 25px;
    }

    @include media('<=phone') {
        margin-left: 5px;
        padding: 15px;
        margin-top: 5px;
    }

    .btn {
        max-width: 180px;
        height: 40px !important;
        line-height: 40px;
        font-weight: 700;
        display: block;
        margin: 30px 0 0;
    }

    .title {
        font-size: 2.4em;
        font-weight: 900;
        margin-bottom: 25px;
        color: $color-white;
        @include media('<=phone') {
            font-size: 2.2em;
        }
    }

    span {
        font-size: 1.5em;

        @include media('<=phone') {
            font-size: 1.1em;
        }
    }
}

.carousel__control {
    position: absolute;
    width: 40px;
    font-size: 1.6em;
    text-align: center;
    height: 115px;
    line-height: 115px;
    top: 50%;
    left: 20px;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    -ms-transform: translate(-50% -50%);
    color: $color-white;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    text-align: center;
    z-index: 9;
    display: none;
}
.carousel--left {
    left: 25px;

    @include media('>desktop') {
        left: 180px;
    }

    @include media('<=phone') {
        left: 10px;
    }
}


.carousel--right {
    right: 0;
    left: auto;

    @include media('>desktop') {
        right: 180px;
    }

    @include media('<=phone') {
        right: -20px;
    }
}

﻿.featured {
    margin-bottom: 30px;

    h2 {
        text-align: center;
        text-transform: uppercase;
        color: $color-primary;
        margin-bottom: 30px;
    }

    .section {
        @include media('<=phone') {
            margin: 0;
        }
    }

    .dw-md-3 {
        @include media('>tablet') {
            width: 20%;
            margin-bottom: 50px;
        }

        @include media('<=tablet') {
            margin-bottom: 30px;
        }
    }

    .dw-md-3:nth-child(5n+1) {
        @include media('>tablet') {
            clear: both;
        }
    }

    .dw-md-3:nth-child(3n+1) {
        @include media('<tablet','>phone') {
            clear: both;
        }
    }

    .dw-md-3:nth-child(2n+1) {
        @include media('<phone') {
            clear: both;
        }
    }

    a {
        &:hover {
            .btn {
                display: block;

                @include media('<=tablet') {
                    display: none;
                }
            }
        }
    }
}
.no--heading {
    h3 {
        display: none;
    }
}
.featured__product {
    background-color: $color-white;
    border-radius: 2px;
    border: 1px solid $color-border-lgrey;
    margin-bottom: 20px;
    position: relative;

    img {
        margin: auto;
        display: block;
    }

    .btn {
        position: absolute;
        max-width: 120px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 600;
        display: none;

        &:hover {
            background-color: $color-white;
            border-color: $color-white;
            color: $color-orange;
        }
    }
}

.featured--title {
    color: $color-dark;
    font-weight: 700;
    margin-bottom: 5px;
    display: block;
}

.featured--reviews {
    margin: 20px 0;
    display: block;
}

.featured--price {
    display: block;
    font-weight: 700;

    .was {
        color: $color-grey;
        font-size: 0.85em;

        .line {
            text-decoration: line-through;
        }
    }

    .now {
        display: block;
        color: $color-primary;
        font-size: 1.2em;
        font-weight: 900;

        span {
            color: $color-grey;
            text-transform: uppercase;
            font-size: 0.7em;
        }
    }

    .save {
        display: block;
        color: $color-red;
        font-size: 0.8em;
    }
}

.featured--badge {
    position: absolute;
    top: 10px;
    left: 30px;
}

.featured-scientific {
    color: $color-orange;
    font-style: italic;
    font-size: 1em;
    font-weight: 700;
}
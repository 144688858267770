﻿.newsletter {
    background-color: $color-primary;
}

.newsletter__form {
    margin: auto;
    padding: 30px 0;
    color: $color-white;

    h2 {
        font-size: 1.4em;
        color: $color-white;
    }

    .newsletter--sign-up {
        font-size: 0.85em;
    }

    .grecaptcha-badge {
        display: none;
    }

    .section {
        @include media('>tablet') {
            margin-left: 50px;
        }

        .dw-md-5 {
            @include media('>tablet') {
                padding-left: 0;
            }
        }
    }

    input[type=text] {
        height: 45px;
        line-height: 45px;
        border-radius: 2px;
        font-size: 0.8em;
        color: $color-dark-grey;
        margin: 18px 0 0;
    }

    .btn--orange {
        height: 45px;
        line-height: 45px;
        max-width: 160px;
        margin: 18px 0;
        font-size: 0.9em;
        font-weight: 700;

        @include media('<=phone') {
            margin: auto;
            max-width: none;
        }
    }

    a {
        color: $color-orange;

        &:hover {
            text-decoration: underline;
        }
    }
}

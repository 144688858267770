﻿.subcategory {
    h1 {
        margin-bottom: 15px;
    }

    h3 {
        margin-bottom: 15px;
    }
}

.spinner {
    position: absolute;
    right: -25px;
    top: 2px;
    font-size: 2em;
    color: #215293;
    line-height: 40px;

    &.hide {
        display: none;
    }
}

.subcategory__top {
    background-color: $color-baby-blue;
}

.subcategory__description {
    color: $color-dark-grey;
    margin-bottom: 50px;
    display: block;

    p {
        display: block;
        margin-bottom: 15px;
    }
}

.subcategory__listing {
    margin-bottom: 80px;


    @include media('<=tablet') {
        padding: 15px;
    }

    .dw-md-3 {

        &:nth-child(4n+1) {
            @include media('>tablet') {
                clear: both;
            }
        }

        &:nth-child(3n+1) {
            @include media('<=tablet', '>phone') {
                clear: both;
            }
        }

        &:nth-child(2n+1) {
            @include media('<=phone') {
                clear: both;
            }
        }
    }
}

.subcategory__product {
    background-color: $color-white;
    border-radius: 2px;
    border: 1px solid $color-border-lgrey;
    margin-bottom: 20px;

    img {
        margin: auto;
        display: block;
    }

    @include media('<=tablet') {
        margin-top: 20px;
    }
}

.subcategory--title {
    color: $color-dark;
    font-weight: 700;
    margin-bottom: 5px;
    display: block;
}

.subcategory--reviews {
    margin-bottom: 20px;
}

.subcategory--price {
    display: block;
    font-weight: 700;
    margin-bottom: 20px;
    clear: both;

    .was {
        color: $color-grey;
        font-size: 0.85em;

        .line {
            text-decoration: line-through;
        }
    }

    .now {
        display: block;
        color: $color-primary;
        font-size: 1.2em;
        font-weight: 700;

        span {
            color: $color-grey;
            text-transform: uppercase;
            font-size: 0.7em;
        }
    }

    .save {
        display: block;
        color: $color-red;
        font-size: 0.8em;
    }
}


.subcategory__sort {
    margin: 30px 0 25px;
    line-height: 40px;
    border-bottom: 1px solid $color-border-lgrey;

    @include media('<=phone') {
        padding: 0 15px;
        font-size: 0.75em;
    }

    .dw-sm-6 {
        margin-bottom: 15px;
    }

    strong {
        display: inline-block;
        color: #215293;
        font-size: 1.2em;
        text-transform: uppercase;
    }

    form {
        max-width: 290px;
        width: 100%;
        text-align: right;
        float: right;
        position: relative;
        padding-left: 85px;

        strong {
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    select {
        border: 1px solid #b4b4b4;
        color: #777;
        font-size: 1em;
    }
}

.prod--badge.list {
    position: absolute;
    top: 10px;
    left: 30px;
}

.no__products--title {
    i {
        color: $color-orange;
        display: inline-block;
        vertical-align: middle;
        float: left;
        margin-right: 15px;
        font-size: 1.5em;
        line-height: 30px;
    }
}

.no__products {
    margin-top: 20px;
    padding-bottom: 50px;
    background-color: $color-baby-blue;
    padding: 25px;
    color: $color-dark-grey;

    strong a {
        color: $color-primary;

        &:hover {
            color: $color-orange;
        }
    }

    .btn {
        max-width: 240px;
        float: left;
        margin: 15px 15px auto auto;
        width: 100%;
        font-weight: 700;

        @include media('<=phone') {
            float: none;
            margin: 15px auto;
        }
    }
}

.is--wysiwyg {
    font-weight: 700;
    display: block;
    color: $color-white;
    background-color: $color-primary;
    max-width: 110px;
    height: 25px;
    line-height: 26px;
    text-align: center;
    margin-bottom: 5px;
}

.sub--scientific {
    color: $color-orange;
    font-style: italic;
    font-size: 0.9em;
    font-weight: 700;
}

.sub__calculator-box {
    background-color: $color-orange;
    padding: 10px;
    text-align: center;
    border-radius: 2px;
    cursor: pointer;
    margin-bottom: 20px;
    display: block;
}

.sub--calc {
    font-size: 1em;
    color: $color-white;
    font-weight: 600;

    i {
        margin-left: 5px;
        color: $color-white;
    }

    span {
        text-decoration: underline;
    }
}

.stock__check {
    display: flex;
    margin-top: 8px;
    color: $color-dark-grey;
    grid-gap: 10px;

    .fa-check-circle {
        color: #079700;
    }

    .fa-check-circle.orange {
        color: #ef762f;
    }

    .fa-times-circle {
        color: $color-red
    }

    .fas.hidden {
        display: none !important;
    }

    .orange-stock {
        padding: 3px;
        color: #fff;
        background-color: #ef762f;
        font-size: 0.85rem;
    }
}

﻿/**
 * BxSlider v4.0 - Fully loaded, responsive content slider
 * http://bxslider.com
 *
 * Written by: Steven Wanderski, 2012
 * http://stevenwanderski.com
 * (while drinking Belgian ales and listening to jazz)
 *
 * CEO and founder of bxCreative, LTD
 * http://bxcreative.com
 */


/** RESET AND LAYOUT
===================================*/

.bx-wrapper {
	position: relative;
	padding: 0;
	*zoom: 1;
}

.bx-wrapper  img{
	display:block;
}


/** THEME
===================================*/

.bx-wrapper .bx-pager,
.bx-wrapper .bx-controls-auto {
	position: absolute;
	bottom: 15px;;
    right: 0%;
    z-index: 999;
}

/* LOADER */

.bx-wrapper .bx-loading {
	min-height: 50px;
	background: url(../img/bx_loader.gif) center center no-repeat #fff;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2000;
}

/* PAGER */

.bx-wrapper .bx-pager {
	text-align: center;
	font-size: .85em;
	font-family: Arial;
	font-weight: bold;
	color: #666;
	width:100%;
}

.bx-wrapper .bx-pager .bx-pager-item,
.bx-wrapper .bx-controls-auto .bx-controls-auto-item {
	display: inline-block;
	*zoom: 1;
	*display: inline;
}


.bx-wrapper .bx-pager.bx-default-pager a {
	background: #fff;
	display: block;
	width: 16px;
	height: 16px;
	margin: 0 5px;
	outline: 0;
	-moz-border-radius: 100%;
	-webkit-border-radius: 100%;
	border-radius: 100%;
    text-indent:-30000px;
}

.bx-wrapper .bx-pager.bx-default-pager a:hover,
.bx-wrapper .bx-pager.bx-default-pager a.active {
	background: #215293 !important;
}

.bx-wrapper .bx-next {
	right: 0px;
}

.bx-wrapper .bx-prev:hover {
	background-position: 0 0;
}

.bx-wrapper .bx-next:hover {
	background-position: -43px 0;
}

.homeslide .bx-wrapper .bx-controls-direction a { display:none; }

.bx-wrapper .bx-controls-direction a {
	position: absolute;
	top: 50%;
    -ms-transform:translate(0,-50%);
    -o-transform:translate(0,-50%);
    -moz-transform:translate(0,-50%);
    -webkit-transform:translate(0,-50%);
    transform:translate(0,-50%);
	outline: 0;
	width: 30px;
	height: 40px;
    line-height:40px;
	z-index: 9999;
    font-size:1.5em;
    color: $color-primary;
    font-weight:700;
    text-align:center;
}

.bx-wrapper .bx-controls-direction a.fa-chevron-left {
	left: 15px;
}

.bx-wrapper .bx-controls-direction a.fa-chevron-right {
	right: 15px;
}

.rightslider .bx-wrapper .bx-controls-direction a { 
    top:50%;
    color:#fff;
    font-size:4em;
}

/* AUTO CONTROLS (START / STOP) */

.bx-wrapper .bx-controls-auto {
	text-align: center;
}

.bx-wrapper .bx-controls-auto .bx-start {
	display: block;
	text-indent: -9999px;
	width: 10px;
	height: 11px;
	outline: 0;
	margin: 0 3px;
}

.bx-wrapper .bx-controls-auto .bx-start:hover,
.bx-wrapper .bx-controls-auto .bx-start.active {
	background-position: -86px 0;
}

.bx-wrapper .bx-controls-auto .bx-stop {
	display: block;
	text-indent: -9999px;
	width: 9px;
	height: 11px;
	outline: 0;
	margin: 0 3px;
}

.bx-wrapper .bx-controls-auto .bx-stop:hover,
.bx-wrapper .bx-controls-auto .bx-stop.active {
	background-position: -86px -33px;
}

/* PAGER WITH AUTO-CONTROLS HYBRID LAYOUT */

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager {
	text-align: left;
	width: 80%;
}

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-controls-auto {
	right: 0;
	width: 35px;
}

/* IMAGE CAPTIONS */

.bx-wrapper .bx-caption {
	position: absolute;
	bottom: 0;
	left: 0;
	background: #666;
	background: rgba(80, 80, 80, 0.75);
	width: 100%;
}

.bx-wrapper .bx-caption span {
	color: #fff;
	font-family: Arial;
	display: block;
	font-size: .85em;
	padding: 10px;
}
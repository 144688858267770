﻿.faqs {
    padding-bottom: 50px;
}
.faq__top {
    cursor: pointer;
    padding: 17px 10px;
    background-color: $color-baby-blue;
    color: $color-primary;
    position: relative;
    margin: 15px 0;
    border-radius: 2px;

    i {
        position: absolute;
        bottom: 0;
        right: 15px;
        text-align: center;
        font-size: 1.2em;
        color: $color-orange;
        line-height: 60px;
    }
}

.faq--title {
    font-weight: 700;
    font-size: 1.38em;
}

.faq__block.closed {
    height: auto;
}
.faq__content.closed {
    height: 0;
    overflow: hidden;
}

.faq__content {
    padding: 0 15px;

    ul {
        li {
            span {
                font-weight: 700;
            }
        }
    }

    img {
        float: right;
        @include media('<=phone') {
            margin: auto;
            float: none;
        }
    }

    a{
        color: $color-orange;
        font-weight: 600;
        text-decoration: underline;
    }
}

.faq--info {
    color: $color-dark-grey;
    display: block;
}

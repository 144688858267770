﻿.guides {
    padding: 50px 0;  

    span {
        display: block;
    }

    .btn {
        margin: 25px 0;
        max-width: 200px;
        font-weight: 600;

        i {
            margin-right: 10px;
        }
    }

    
}

.guides__block {
    background-color: $color-white;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.17);
    border-radius: 2px;
    padding: 30px 20px 0;
    position: relative;

    .dw-md-4 {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 42.6%;

        @include media('<=phone') {
            width: 33%;
        }

        padding-right: 0;
    }

    .guide--calc {
        width: 33.3%;
    }
}

.guides__block--title {
    color: $color-primary;
    font-weight: 700;
    font-size: 1.2em;
    margin-bottom: 10px;
    min-height: 48px;
}

.guides__block--info {
    color: $color-dark-grey;
    font-size: 0.88em;

    @include media('<=phone') {
        max-width: 275px;
    }
}

.guide--divide {
   margin-bottom: 50px;
}

.calc-form--surround.open {visibility: visible;}
﻿.checkout__form {
	border: 1px solid $color-baby-blue;
	margin-bottom: 50px;

	#contactform {
		max-width: none;
		padding: 0;
		margin: auto;

		.details--title {
			margin: auto;
			padding: 25px;
			border-bottom: 1px solid $color-baby-blue;
		}

		h2 {
			display: inline-block;
			margin: 0;
		}
	}

	input, select {
		border: 1px solid $color-checkout;
		margin: 10px 0;
		border-radius: 2px;
	}
}

.checkout__surround {
	i {
		color: $color-orange;
		margin: 0 18px;
		font-size: 2em;
		vertical-align: middle;
	}

	#map {
		width: 100%;
		height: 210px;
		float: right;
		margin-top: 15px;
	}
}

.my--details {
	padding: 25px;
	margin-top: 10px;
}

.checkout--heading {
	border-bottom: 1px solid $color-baby-blue;
	border-top: 1px solid $color-baby-blue;
	padding: 10px;
	margin-bottom: 10px;
}

.sab {
	float: right;
	margin-top: 5px;
	line-height: 25px;
}

.sab input {
	width: 21px;
	height: 21px;
	border: 1px solid #d6d6d6;
	margin: 2px 0 2px 10px;
	float: right;
}
.sab span {
	color: $color-dark;
	line-height: 25px;
	float: left;
	display: block;

	@include media('<=phone') {
		margin-top: 10px;
	}
}

.address--details {
	padding: 25px;
}


#delivery-options {
	padding: 25px;

	.delivery-opt.selected {
		border: 1px solid $color-orange;

		strong {
			color: $color-orange;
		}
	}

	input {
		margin: 0;
		width: 30px;
		height: 30px;

		@include media('<=phone') {
			width: 15px;
			height: 15px;
		}
	}

	strong {
		color: $color-dark;
		padding-left: 25px;

		@include media('>phone') {
			width: 45%;
			float: left;
		}

		@include media('<=phone') {
			padding-left: 0;
		}
	}

	.details {
		span {
			color: $color-dark;

			@include media('>phone') {
				width: 55%;
				float: right;
				padding-left: 15px;
			}
		}
	}

	span.price {
		color: $color-orange;
		font-weight: 700;
		position: absolute;
		right: 15px;
		top: 50%;
		-ms-transform: translate(0,-50%);
		-o-transform: translate(0,-50%);
		-moz-transform: translate(0,-50%);
		-webkit-transform: translate(0,-50%);
		transform: translate(0,-50%);
		line-height: 40px;
		width: 85px;
		font-size: 1.2em;
	}

	label {
		padding: 15px 115px 15px 44px;
		position: relative;
		display: block;
		margin-top: 15px;
		border: 1px solid $color-checkout;
		border-radius: 2px;

		@include media('<=phone') {
			padding: 15px 65px 15px 44px;
		}
	}
}


.payment__options {
	i {
		color: $color-orange;
		margin: 0 18px;
		font-size: 2em;
		vertical-align: middle;
	}

}

.payment__options--conf {
	display: inline-block;
}

input#NoConfirmationEmail {
	width: 25px;
	height: 25px;
	vertical-align: middle;
	margin-left: 10px;
	margin-top: 0;
}
.radio--buttons {
	background-color: $color-white;
	border: 1px solid $color-checkout;
	max-width: 300px;
	padding: 5px 20px;
	display: block !important;
	margin: 10px auto;
	color: $color-primary;
	font-weight: 600;
	text-align: center;
	cursor: pointer;

	i {
		color: $color-orange;
		margin: 0 10px;
		font-size: 1.2em;
		vertical-align: middle;
	}
}

.telephonepayment {
	margin-bottom: 15px;
}

.email--conf {
	display: flex;
	justify-content: flex-end;

}

.amount--pay {
	background-color: $color-baby-blue;
	max-width: 570px;
	margin: 0 15px 15px;

	@include media('<tablet','>phone') {
		max-width: 470px;
	}
}

#somecash {
	h3 {
		padding: 20px;
		font-size: 1em;
	}

	.dw-sm-7, .dw-md-2 {
		padding: 0;
	}

	input#PartialCashAmount {
		font-size: 1.2em;
		font-weight: bold;
	}
}

.checksig {
	padding: 25px;

	.agree a {
		color: $color-orange;
	}

	.agree input {
		width: 25px;
		height: 25px;
		vertical-align: middle;
		margin-right: 10px;
	}

	input.btn {
		border: 1px solid $color-orange;
		font-weight: 600;
		margin: 0 0 10px 0;
	}

	.btn.paypal {
		background-color: $color-yellow;
		border: 1px solid $color-yellow;
		margin-bottom: 10px;

		img {
			margin: auto;
		}

		&:hover {
			background-color: $color-checkout;
			border: 1px solid $color-checkout;
		}
	}

	.dw-xs-6.type {
		padding-left: 0;
	}
}

#billingAddress.shut {
	height: 0;
	overflow: hidden;
}

/*Checkout*/
.pay-types .pay-srnd {
	border: 1px solid #d4d4d4;
	background-color: #fff;
	color: #215293;
	min-height: 58px;
	margin-bottom: 10px;
}

.pay-types .pay-srnd label {
	padding: 15px 70px 15px 15px;
	display: block;
	cursor: pointer;
	position: relative;
	min-height: 76px;
}

.pay-types .pay-srnd label img {
	display: block;
	float: left;
}

.pay-types .text {
	margin: 0 20px 0 20px;
}

.pay-srnd span.check {
	width: 40px;
	height: 40px;
	text-align: center;
	line-height: 40px;
	color: #215293;
	border: 1px solid #d4d4d4;
	display: block;
	position: absolute;
	top: 16px;
	right: 16px;
}

.pay-srnd span.check i {
	width: 40px;
	line-height: 40px;
	height: 40px;
	text-align: center;
	color: #215293;
	display: none;
	font-size: 1.5em;
}

.pay-srnd input {
	display: none;
}

.pay-srnd.selected span.check i {
	display: block;
}

.geotrust {
	float: right;
	height: 55px;
	width: 115px;
	margin-bottom: 15px;
}

#delivery .del-opt {
	width: 50%;
	max-width: 320px;
	float: left;
	margin-right: 0px;
}

#delivery .del-opt .surround {
	background-color: #d1dad4;
	font-size: 1.1em;
	padding: 10px;
	margin-bottom: 0px;
	cursor: pointer;
}

#delivery .del-opt strong span {
	color: #f77400;
}

#delivery .del-opt .check {
	float: right;
	width: 40px;
	height: 40px;
	line-height: 40px;
	background-color: #fff;
}

#delivery .del-opt.collect .check {
	margin-top: -20px;
}

#delivery .del-opt .check i {
	color: #f77400;
	font-size: 1.5em;
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	display: none;
}

#delivery .del-opt.selected .check i {
	display: block;
}

#delivery .del-opt.collect {
	line-height: 20px;
}

.account.other .bill-del h2 {
	line-height: 25px;
	float: left;
}

.account.other #delivery h2 {
	margin-bottom: 0px;
}

#delivery-options label .rb {
	position: absolute;
	left: 15px;
	top: 50%;
	-ms-transform: translate(0,-50%);
	-o-transform: translate(0,-50%);
	-moz-transform: translate(0,-50%);
	-webkit-transform: translate(0,-50%);
	transform: translate(0,-50%);
	width: 14px;
	height: 40px;
}




.required {
	color: $color-orange;
}

.pay-opt h2 {
	float: left;
	line-height: 40px;
	margin-top: 0px;
}

.pay-opt span.btn {
	float: right;
	display: block;
	padding: 0 15px;
	height: 40px;
	line-height: 40px;
	width: auto;
	background-color: #215293;
	color: #fff;
	border: 1px solid #215293;
	cursor: pointer;
	text-transform: uppercase;
	font-size: 0.85em;
}

.pay-opt span.btn:hover {
	background-color: #fff;
	color: #215293;
}

.pay-opt .card-surround {
	background-color: #fff;
	position: relative;
	padding: 10px 15px;
	margin-bottom: 15px;
	cursor: pointer;
}

.pay-opt .card-surround input[type="radio"], .hider input[type="checkbox"] {
	position: absolute;
	left: -500000px;
}

.pay-opt .card-surround .check {
	float: left;
	width: 20px;
	text-align: center;
	height: 20px;
	line-height: 20px;
	background-color: #fff;
	margin-right: 15px;
	border: 1px solid #d4d4d4;
}

.pay-opt .card-surround .check i {
	color: #215293;
	font-size: 1.5em;
	width: 40px;
	text-align: center;
	height: 40px;
	line-height: 40px;
	display: none;
}

.pay-opt .card-surround.selected .check i {
	display: block;
}

.pay-opt .card-surround .card-details {
	float: left;
	line-height: 58px;
	text-align: center;
	margin-left: 48px;
}

.pay-opt .card-surround .card-expiry {
	line-height: 58px;
	text-align: center;
	margin-left: 0 !important;
}

span.small-text {
	font-size: 0.7em;
}

.payment--blue {
	background-color: $color-baby-blue;
	padding-top: 10px;
	border-radius: 2px;
	margin-bottom: 10px;

	img {
		float: left;
		margin: 15px 15px 15px 8px;
	}

	.payment--orange {
		color: $color-orange;
	}

	label {
		@include media('<desktop','>tablet') {
			font-size: 0.85em;
		}
	}
}
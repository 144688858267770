﻿.footer {
	background-color: $color-second;
	color: $color-white;
	padding: 30px 0;
	font-size: 1em;

	@include media('<=phone') {
		padding-left: 15px;
	}

	.dw-sm-4:first-child {
		@include media('<=tablet') {
			width: 31%;
		}

		@include media('<=phone') {
			width: 100%;
		}
	}

	.dw-sm-4:nth-child(2) {
		@include media('<=tablet') {
			width: 36.333333%;
		}

		@include media('<=phone') {
			width: 100%;
			margin-bottom: 20px;

			.footer--title, .foot--links {
				/*display: none;*/
			}
		}
	}

	.dw-sm-4:nth-child(3) {
		@include media('<=tablet') {
			width: 32%;
		}

		@include media('<=phone') {
			width: 100%;
		}
	}

	.dw-sm-12 {
		@include media('<=tablet') {
			clear: both;
			padding-top: 20px;
		}

		@include media('<=phone') {
			display: none;
		}
	}
}

.footer--title {
	font-size: 1.2em;
	font-weight: 700;
	display: block;
	margin-bottom: 20px;
}

.footer--contact {
	margin-top: 25px;
	a {
		color: $color-white;
		font-weight: 700;
		margin-bottom: 10px;
		display: block;
		&:hover {
			color: $color-orange;
		}
	}

}

.footer--times {
	display: block;
	margin-bottom: 8px;
}

.footer__socials {
	margin-top: 20px;
}

.footer--icons {
	height: 35px;
	width: 35px;
	line-height: 35px !important;
	background-color: $color-white;
	color: $color-second;
	border-radius: 100%;
	text-align: center;
	margin-right: 8px;
	font-size: 1.2em;

	&:hover {
		background-color: $color-orange;
		color: $color-white;
	}

	@include media('<desktop','>tablet') {
		height: 30px;
		width: 30px;
		line-height: 30px !important;
		font-size: 1em;
	}
}

.foot--links {
	color: $color-white;
	display: block;
	margin: 10px 0;
	font-weight: normal;

	&:hover {
		color: $color-orange;
	}
}

.footer__link {
	@include media('<=phone') {
		display: none;
	}

	.foot--links {
		margin-top: 0;
	}
}

.footer__link--first {
	@include media('>tablet') {
		width: 50%;
	}
}

.footer__link--middle {
	@include media('>tablet') {
		width: 50%;
	}
}

.footer--line {
	border: 1px solid $color-primary;
	margin: 65px 0 30px;
}

.footer-bottom--links {
	font-size: 0.8em;
	color: $color-white;

	@include media('<=phone') {
		text-align: center;
	}

	.dw-md-4.right {
		text-align: right;

		@include media('<=phone') {
			text-align: center;
		}
	}

	img {
		margin: auto;
		text-align: center;
		display: block;
	}
}

.dw--link {
	color: $color-white;
	&:hover {
		color: $color-orange;
	}
}


﻿.care {
    color: $color-dark-grey;
    
    @include media('<=phone') {
        margin-top: 85px;
    }

    a {
        color: $color-orange;
        font-weight: 600;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    .btn {
        max-width: 160px;
        margin: auto;
        color: $color-white;
        text-decoration: none;
    }
}

.care.coral {
    .btn {
        max-width: 215px;

        @include media('<=phone') {
            font-size: 0.9em;
        }

        @include media('<=320px') {
            font-size: 0.75em;
        }
    }
}

.care__top {
    position: absolute;
    bottom: -105px;

    @include media('<=tablet') {
        bottom: -120px;
    }

    @include media('<=phone') {
        bottom: -140px;
    }
}

.care__top--left {
    left: 220px;

    @include media('>desktop') {
        left: 300px;
    }

    @include media('<desktop','>tablet') {
        left: 0;
    }

    @include media('<=tablet') {
        left: 0;
    }

    @include media('<=phone') {
        left: -35px;
        width: 75%;
    }
}

.care__top--right {
    right: 220px;

    @include media('>desktop') {
        right: 300px;
    }

    @include media('<desktop','>tablet') {
        right: 0;
    }

    @include media('<=tablet') {
        right: 0;
    }

    @include media('<=phone') {
        display: none;
    }
}

.care__block {
    max-width: 700px;
    margin: auto auto 50px;
    text-align: center;

    h2 {
        margin-bottom: 30px;
    }

    .care--mob {
        @include media('<=phone') {
            margin: 0 25%;
        }
    }
}

.care__block-blue {
    background-color: $color-baby-blue;
    max-width: 1185px;
    margin: auto auto 50px;
}

.care__content {
    padding: 25px 20px;

    h2 {
        margin-bottom: 20px;
    }
}

.care--text {
    @include media('>tablet') {
        width: 51.2%;
    }
}

.care--image {
    @include media('<desktop','>tablet') {
        padding-right: 15px!important;
    }

    @include media('>tablet') {
        width: 48.8%;
        padding-right: 0;
    }

    img {
        @include media('>tablet') {
            float: right;
        }

        margin: auto;
        width: 100%;
    }
}

.care--list {
    padding-top: 60px;

    li {
        font-weight: 600;
    }
}

.care__block-split {
    padding: 0 10px;
    margin-bottom: 50px;

    h2 {
        margin-bottom: 20px;

        @include media('<=phone') {
            margin-top: 20px;
        }
    }
}

.care__help {
    background-color: $color-baby-blue;
    max-width: 1185px;
    margin: 90px auto 25px auto;
    text-align: center;
    padding: 25px 30px;
    color: $color-primary;
    font-weight: 600;
    font-size: 1.5em;
}

.care--coral {
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    justify-content: center;

    li {
        margin: 15px;
        font-weight: 600;
    }
}
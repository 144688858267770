﻿#review {
	margin-bottom: 50px;
}
.review-stars {
	position: relative;
	max-width: 200px;
	width: 100%;
	height: 18px;
	line-height: 18px;
	font-size: 0.9em;
	display: block;
	float: left;
	margin: 20px 0;
}
#review-form {
	padding: 15px;

	.btn {
		margin: 40px auto;
		max-width: 350px;
	}
}

.review__form {
	width: 100%;
	max-width: none !important;
	padding: 0 !important;
	margin-bottom: 20px;

	.editor-label {
		color: $color-primary;
		font-weight: 600;
	}
}

.review-stars .stars {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	overflow: hidden;
}

.review-stars .stars.blank {
	width: 100%;
	color: $color-grey-star;
}

.review-stars .stars.full {
	color: $color-yellow;
}

.review-stars .star-connect {
	width: 200px;
	display: block;
}

.review-stars .stars i {
	width: 18px;
	height: 18px;
	line-height: 18px;
	float: left;
}

#review {
	h1 {
		font-weight: 400;
	}

	span {
		color: $color-dark;
		margin: 15px 0;
		display: block;
	}
}




.reviews__new {
	
	border: 1px solid $color-baby-blue;
	padding: 15px 15px 0;
	border-radius: 2px;
	display: block !important;

	.editor-label.no--comment {
		line-height: 40px;
	}

	.editor-label {
		color: $color-dark;
	}

	.editor-label.comment {
		margin-bottom: 10px;
		max-height: 60px;
		overflow: hidden;
	}

	.btn {
		margin: 30px auto;
		max-width: 300px;
	}

	h2 {
		font-size: 1.4em;
		margin-bottom: 15px;
	}

	a {
		display: none;
	}
}

.reviews__new.hiding {
	a {
		display: block;
		margin-bottom: 10px;
		font-size: 0.8em;
		text-transform: uppercase;
		text-decoration: underline;
		font-weight: 600;
		color: $color-orange;
	}
}

.rev-view {
	width: 100%;
	max-width: 600px;

	.fancybox-title-float-wrap .child {
		display: none;
	}

	.editor-label.comment {
		max-height: none;
	}
}

.rev-view .reviews__new {
	width: 100%;
	max-width: 560px;
}

.rev-view a.fancybox {
	display: none !important;
}


.reviews--g-cap {
	position: relative;
}

.review--split {
	padding: 0;
}

.review--left {
	position: static;
}

.review--right {
	float: right;

	@include media('<=phone') {
		float: none;
	}
}

.review--help {
	padding: 15px;
	color: $color-white;
	background-color: $color-primary;
	font-weight: 700;
	margin: 15px 10px;
	border-radius: 2px;
}

.review--rate {
	vertical-align: middle;
	line-height: 35px;
}

.review--full, .review--selected {
	color: $color-yellow;
}

.review--complete {
	@include media('>desktop') {
		width: 50%;
	}

	@include media('>phone') {
		float: right;
	}
}

.review__rating {
	padding: 15px 30px;

	.section {
		/*background-color: $color-light-grey;*/
		border-radius: 2px;
	}

	.title {
		text-align: center;
		padding-top: 23px;
	}
}

.review__rating--key {
	padding: 15px 0;
}


.review--capt {
	.g-recaptcha {
		float: right;
		margin-top: 35px;
	}
}

.review--box {
	margin-bottom: 20px;
	transition: transform 0.8s;

	.review-close {
		display: none;
		position: absolute;
		top: 0;
		right: 0;
		background-color: #ef762f;
		border-radius: 50%;
		transform: translate(50%, -50%);

		i {
			color: #fff;
			font-size: 2rem;
		}
	}
	/*	@include media('>phone') {
		padding: 0 10px !important;
	}*/
}

.review--box.on {
	padding: 15px;
	position: fixed;
	top: 50%;
	right: 50%;
	background-color: #fff;
	transform: translate(50%,-50%);
	width: 90%;
	max-width: 400px;
	z-index:999;

	.reviews__new {
		max-height: calc(100vh - 75px);
		overflow: auto;
	}

	.read-more {
		display: none !important;
	}

	.review-close {
		display: block;
	}
}

.review-overlay {
	display:none;
	
}

.review-overlay.on {
	display:block;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	background-color: #00000070;
	z-index:990;
}

.review__customers {
	h2 {
		margin-bottom: 25px;
	}
}

.cust-revs {
	font-size: 0.85em;

	.sun {
		margin: 10px 0;
	}

	i {
		width: 20px !important;
	}

	.review-stars {
		font-size: 1.2em;
	}
}

.review--box:nth-child(4n+1) {
	clear: both;
}

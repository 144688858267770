﻿.blog {
    background-color: $color-baby-blue; 

    h2 {
        color: $color-primary;
        text-transform: uppercase;
        text-align: center;
        margin: 50px auto 40px;
    }
}

.blog--image {
    position: relative;
    padding-bottom: 52.66%;
    margin-bottom: 25px;

    div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 5%;
        border-radius: 2px;
    }
}

.blog--date  {
    color: $color-blue;
    font-size: 0.9em;
    text-transform: uppercase;
}

.blog--content {
    font-size: 0.9em;
    color: $color-dark-grey;
}

.blog--title {
    color: $color-primary;
    font-size: 1.3em;
    min-height: 50px;
    margin: 20px 0;
    font-weight: 700;

    @include media('<=phone') {
        min-height: auto;
    }
}

.blog--link {
    background-color: $color-orange;
    border-color: $color-orange;
    color: $color-white;
    font-weight: 700;
    margin: 30px 0 65px;
    display: block;
    text-align: center;
    max-width: 160px;
    height: 40px;
    line-height: 40px;
    border-radius: 2px;

    &:hover {
        background-color: $color-dark-orange;
        border-color: $color-dark-orange;
    }
}

﻿.creative {
    background: url('../img/creative-selection.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 820px;

    @include media('<desktop','>tablet') {
        background-position: 30%;
    }

    @include media('<=tablet') {
        background-position: 50%;
        height: auto;
    }

    .section {
        margin: auto 0;
    }

    .creative--top {
        max-width: 920px;
    }

    .dw-sm-4.middle {
        @include media('<=phone') {
            margin: 0 25%;
        }
    }

    .dw-sm-4.middle.bottom {
        @include media('<=tablet') {
            margin-bottom: 25px;
        }

        @include media('<=phone') {
            margin-bottom: 50px;
        }
    }

    .btn {
        max-width: 160px;
        margin: auto;
        font-weight: 700;

        @include media('<=phone') {
            font-size: 0.9em;
        }
    }

    @include media('<=phone') {
        padding-bottom: 40px;
    }
}

.creative__info {
    color: $color-white;
    font-weight: 700;
    font-size: 1.65em;
    padding: 10px 15px;
    border-radius: 2px;

    @include media('<desktop','>tablet') {
        font-size: 1.5em;
    }

    @include media('<=tablet') {
        font-size: 1.3em;
    }
}

.creative--info-one {
    margin-top: 65px;
    background-color: rgba(19,96,184,0.7);
    max-width: 590px;
    min-height: 85px;
    @include media('<=phone') {
        margin-top: 30px;
    }
}

.creative--info-two {
    margin: 25px 0;
    background-color: $color-creative-blue;
    max-width: 870px;
    padding: 15px;

    @include media('<desktop','>tablet') {
        max-width: 665px;
    }
}

.creative__find {
    background-color: $color-creative-blue;
    border-radius: 2px;
    padding: 0;
    position: relative;
    max-width: 285px;

    @include media('<=tablet') {
        max-width: none;
        display: block;
        margin-bottom: 25px;
    }

    img {
        position: absolute;
        bottom: 0;

        @include media('<=tablet') {
          display: none;
        }
    }
}

.coral--finder {
    @include media('<=tablet') {
       clear: both;
       margin-top: 20px;
    }
}

.creative--browser {
    background-color: $color-white;
    padding: 15px 30px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    text-align: center;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        left: 44%;
        top: 100%;
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 20px solid $color-white;
        clear: both;

        @include media('<desktop','>tablet') {
            top: 95%;
        }

        @include media('<=tablet') {
            top: 95%;
        }
    }

    i {
        color: $color-orange;
        font-size: 2em;
        vertical-align: middle;
        margin-right: 15px;
    }

    span {
        color: $color-creative-blue;
        font-weight: 600;
        font-size: 1.5em;

        @include media('<desktop','>tablet') {
            font-size: 1.2em;
        }
    }
}

.creative--search {
    margin-top: 20px;
    font-weight: 600;
    text-align: center;
    padding: 15px;
    color: $color-white;
    position: relative;
    display: block;

    input {
        margin-top: 15px;
    }

    .btn {
        margin-top: 15px;
        width: 100%;
        max-width: none;
    }
}
﻿/* BREAKPOINTS ------------ */
$breakpoints: ( 
    'phone': 767px, 
    'tablet': 991px, 
    'desktop': 1230px 
) !default;
/*$breakpoints: ( 
    'phone': 320px, 
    'tablet': 768px, 
    'desktop': 1024px 
) !default;*/


/* COLOURS ------------ */
$color-primary: #1262bc;
$color-second: #215293;
$color-baby-blue: #e1f1fe;
$color-blue: #68bbf8;
$color-creative-blue: #1360b7;
$color-white: #fff;
$color-orange: #ef762f;
$color-dark-orange: #ef5e08;
$color-yellow: #ddb600;
$color-red: #c73427;
$color-grey: #aeaeae;
$color-grey-star: #bbbbbb;
$color-border-lgrey: #f3f3f3;
$color-border-form: #ececec;
$color-border-grey: #d5d5d5;
$color-dark-grey: #666666;
$color-tabs: #f6f6f6;
$color-dark: #071134;
$color-black: #333333;
$color-check:#dfdfdf;
$color-pad: #f9fcff;
$color-navy: #071134;
$color-dark-blue: #012b52;
$color-checkout: #e1e1e1;
$color-browse-number: #ef7631;
$color-browse-now: #d0dff2;
$color-browse-black: #000000;
﻿.internal {
    padding-bottom: 60px;

    .breadcrumb {
        display: none;
    }

    iframe {
        max-width: 100%;
    }

    ul {
        padding: 0 15px;
        margin: 10px 0;

        li {
            color: $color-orange;

            span {
                color: $color-dark-grey;
            }
        }
    }

}

.internal--other {
    height: 150px !important;

    @include media('<=phone') {
        height: auto !important;
    }
}

.internal.other {
    background-color: $color-baby-blue;

    h2 {
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 45px;
    }
}

.internal.pdf {
    h1 {
        top: 15% !important;
    }
}

.internal__banner {
    background-image: url('../img/rockncritters-internal-banner.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 270px;
    margin-bottom: 50px;
    position: relative;

    h1 {
        position: absolute;
        top: 25%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $color-white;
        margin-bottom: 20px;
        text-align: center;

        @include media('<=phone') {
            position: initial;
            transform: initial;
            left: auto;
            top: auto;
        }
    }

    .internal__banner--text {
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $color-white;
        text-align: center;
        font-size: 1.1em;
        max-width: 795px;
        width: 100%;
        padding: 0 15px;

        @include media('<=phone') {
            position: initial;
            transform: initial;
            left: auto;
            top: auto;
            display: block;
        }

        a {
            color: $color-orange;
        }
    }
}

.internal__banner {
    @include media('<=phone') {
        height: auto;
        padding-bottom: 20px;
    }
}


.internal.other {
    .internal__banner {
        margin-bottom: 0;

        h1 {
            top: 30%;

            @include media('<=phone') {
                top: 18%;
                width: 100%;
                z-index: 1;
            }
        }
    }
}

.internal__content {
    padding: 0 185px;

    @include media('<=tablet') {
        padding: 0 70px;
    }

    @include media('<=phone') {
        padding: 0 20px;
    }

    h3 {
        margin-top: 15px;
    }

    a {
        color: $color-primary;
        text-decoration: underline;
        font-weight: 700;

        &:hover {
            color: $color-orange;
        }
    }

    ol {
        padding-left: 15px;

        li {
            color: $color-primary;
            font-weight: 700;
            margin: 15px 0;
        }

        span {
            color: $color-dark-grey;
            font-weight: normal;
        }

        .internal--number {
            color: $color-primary;
            font-weight: 700;
        }
    }
}

.internal__content.about {
    padding: 0;
    
    h3 {
        margin-bottom: 20px;
    }

    a.btn {
        color: $color-white;
        text-decoration: none;
        max-width: 265px;
        margin: 60px auto;
    }
}

.internal__section {
    img {
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.17);
        border-radius: 2px;
        margin-bottom: 25px;

        @include media('<=tablet') {
            margin: 10px auto;
        }
    }
}

.internal__creative {
    margin-top: -70px;
    min-height: 445px;
    position: relative;
    margin-bottom: 45px;


    @include media('<=tablet') {
        margin-bottom: 0;
    }

    @include media('<=phone') {
        min-height: 230px;
        margin-top: -15px;
    }

    iframe {
        position: relative;
        z-index: 1;
        margin: auto;
        display: block;
        box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.4);
        border: none;

        @include media('<=tablet') {
            max-width: 525px;
            height: 300px
        }

        @include media('<=phone') {
            max-width: 300px;
            height: 172px;
        }
    }
}

.internal__creative.forms {
    .internal__creative--images {
        @include media('<=phone') {
            position: initial;
        }
    }

    .image--right {
        @include media('<=phone') {
            display: none;
        }
    }
}

.internal__creative--images {
    position: absolute;
}

.image--left {
    left: 13.6%;
    top: -30px;

    @include media('<desktop','>tablet') {
        left: -5%;
    }

    @include media('<=tablet') {
        left: -8%;
        max-width: 320px;
    }

    @include media('<=phone') {
        max-width: 180px;
        left: -20%;
    }
}

.image--right {
    right: 13.6%;
    top: 0;

    @include media('<desktop','>tablet') {
        right: 0;
    }

    @include media('<=tablet') {
        right: 0;
        max-width: 280px;
    }

    @include media('<=phone') {
        max-width: 180px;
    }
}

.image-fish--left {
    left: 20%;
    bottom: 0;
    z-index: 2;

    @include media('<desktop','>tablet') {
        left: 0;
    }

    @include media('<=tablet') {
        left: -5%;
        bottom: 15%;
    }

    @include media('<=phone') {
        max-width: 180px;
        left: -25%;
    }
}

.image-fish--right {
    right: 20%;
    bottom: 0;
    z-index: 2;

    @include media('<desktop','>tablet') {
        right: 0;
    }

    @include media('<=tablet') {
        right: 0;
        bottom: 15%;
    }

    @include media('<=phone') {
        display: none;
    }
}

.internal--body {
    display: block;
    color: $color-dark-grey;

    a {
        color: $color-primary;
        text-decoration: underline;
        font-weight: 700;

        &:hover {
            color: $color-orange;
        }
    }
}

.internal--body.decide {
    text-align: center;
    margin: 30px auto;
    max-width: 690px;
    padding: 15px;
}

.internal__bottom {
    margin: auto;

    h3, .internal--body {
        text-align: center;
    }
}

.internal__address {
    padding: 50px 25px;

    h3 {
        margin-top: 0;
    }

    .btn {
        margin: 0 !important;
        max-width: 170px !important;
    }

    i {
        margin-right: 15px;
        color: $color-orange;
        display: block;
        float: left;
        font-size: 1.5em;
    }
}

.internal__map {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.17);
    background-color: $color-white;
    border-radius: 2px;
    margin: 50px 0;

    iframe {
        width: 100%;
    }

    .dw-md-6.map {
        max-height: 385px;
    }
}

.bottom--gallery {
    img {
        box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.4);
    }
}

.internal__contact {
    margin-bottom: 80px;

    i {
        text-align: center;
        color: $color-orange;
        font-size: 1.4em;
        display: block;

        @include media('<=phone') {
            margin-top: 15px;
        }
    }
}

.internal__contact--title {
    color: $color-primary;
    text-align: center;
    font-weight: 700;
    font-size: 1.2em;
    margin: 10px auto;
    display: block;
}

.internal__contact--details {
    color: $color-dark-grey;
    text-align: center;
    display: block;

    a {
        display: block;
        color: $color-second;
        font-weight: 700;
    }

    strong {
        color: $color-second;
        font-weight: 700;
    }
}

#decideform {
    padding: 55px 110px;
    max-width: 700px;

    @include media('<=tablet') {
        padding: 25px;
    }
}

#askform {
    padding: 55px 110px;
    max-width: 700px;
    position: relative;
    z-index: 1;
    margin: auto;
    display: block;
    background-color: $color-white;

    @include media('<=tablet') {
        padding: 25px;
    }
}
#contactform {
    max-width: 590px;
    margin-top: 80px;
    padding: 50px 80px 80px;

    @include media('<=tablet') {
        padding: 25px;
    }

    h2 {
        text-transform: capitalize;
        margin-top: 20px;
    }

    textarea {
        min-height: 170px;
    }

    .editor-field.checkbox {
        input {
            vertical-align: middle;
            height: 25px;
            width: 25px;
        }
    }

    .privacy {
        font-weight: 600;
        display: block;
        margin-top: 15px;

        a {
            color: $color-primary;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
}
.internal__forms {
    border-radius: 2px;
    background-color: $color-white;
    margin: auto;

    .option {
        input {
            height: auto;
        }

        span {
            margin: 0 15px;
        }
    }

    .btn {
        height: 50px;
        line-height: 50px;
        margin-top: 20px;
        font-weight: 600;
        @include primary-font-stack;
    }

    .editor-label {
        color: $color-dark-grey;
        font-weight: 600;
        margin: 15px 0;
        font-size: 0.95em;
    }

    .editor-field {
        margin-bottom: 15px;
    }

    .editor-field.options {
        margin-top: 15px;
    }

    i {
        text-align: center;
        color: $color-orange;
        font-size: 2em;
        display: block;
    }

    input, select {
        border-radius: 2px;
        height: 45px;
        line-height: 45px;
        font-family: inherit;
        border: 1px solid $color-border-form;
    }

    textarea {
        border-radius: 2px;
        font-family: inherit;
        border: 1px solid $color-border-form;
    }
}

.help--decide {
    .internal__banner--text {
        max-width: 480px;
    }
}

.internal__image {
    position: absolute;
    bottom: 0;
}

.internal__image--left {
    left: 12%;

    @include media('<desktop','>tablet') {
        left: -8%;
    }

    @include media('<=tablet') {
        display: none;
    }
}

.internal__image--right {
    right: 12%;

    @include media('<desktop','>tablet') {
        right: 0;
    }

    @include media('<=tablet') {
        display: none;
    }
}

.note--required {
    margin-top: 30px;
    font-style: italic;
    color: $color-dark-grey;
    text-align: center;
}
﻿*, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.fl-al-cent{align-items: center; }

.my-15 {margin-top: 15px; margin-bottom: 15px;}

.container {
    margin-right: auto;
    margin-left: auto;
}

.container:before, .container:after, .section:before, .section:after {
    display: table;
    content: " ";
}

.container:after, .section:after {
    clear: both;
}

.clear {
    clear: both;
    display: block;
}

.svg-inline--fa.fa-w-18 {
    width: 60px;
    color: #ff6800;
}

.dw-xs-1, .dw-sm-1, .dw-md-1, .dw-lg-1,
.dw-xs-2, .dw-sm-2, .dw-md-2, .dw-lg-2,
.dw-xs-3, .dw-sm-3, .dw-md-3, .dw-lg-3,
.dw-xs-4, .dw-sm-4, .dw-md-4, .dw-lg-4,
.dw-xs-5, .dw-sm-5, .dw-md-5, .dw-lg-5,
.dw-xs-6, .dw-sm-6, .dw-md-6, .dw-lg-6,
.dw-xs-7, .dw-sm-7, .dw-md-7, .dw-lg-7,
.dw-xs-8, .dw-sm-8, .dw-md-8, .dw-lg-8,
.dw-xs-9, .dw-sm-9, .dw-md-9, .dw-lg-9,
.dw-xs-10, .dw-sm-10, .dw-md-10, .dw-lg-10,
.dw-xs-11, .dw-sm-11, .dw-md-11, .dw-lg-11,
.dw-xs-12, .dw-sm-12, .dw-md-12, .dw-lg-12 {
    position: relative;
    min-height: 1px;
    display: block;
}

@media (min-width:768px) {
    .dw-xs-1, .dw-sm-1, .dw-md-1, .dw-lg-1,
    .dw-xs-2, .dw-sm-2, .dw-md-2, .dw-lg-2,
    .dw-xs-3, .dw-sm-3, .dw-md-3, .dw-lg-3,
    .dw-xs-4, .dw-sm-4, .dw-md-4, .dw-lg-4,
    .dw-xs-5, .dw-sm-5, .dw-md-5, .dw-lg-5,
    .dw-xs-6, .dw-sm-6, .dw-md-6, .dw-lg-6,
    .dw-xs-7, .dw-sm-7, .dw-md-7, .dw-lg-7,
    .dw-xs-8, .dw-sm-8, .dw-md-8, .dw-lg-8,
    .dw-xs-9, .dw-sm-9, .dw-md-9, .dw-lg-9,
    .dw-xs-10, .dw-sm-10, .dw-md-10, .dw-lg-10,
    .dw-xs-11, .dw-sm-11, .dw-md-11, .dw-lg-11,
    .dw-xs-12, .dw-sm-12, .dw-md-12, .dw-lg-12,
    .container {
        padding: 0 15px;
    }

    .section {
        margin-right: -15px;
        margin-left: -15px;

        &.flx {
            > .clear {display: none;}
            &:before{display: none;}
            &:after{display: none;}

            flex-wrap: wrap;
            display: flex;
        }
    }
}

@media (max-width:767px) {
    .dw-xs-1, .dw-sm-1, .dw-md-1, .dw-lg-1,
    .dw-xs-2, .dw-sm-2, .dw-md-2, .dw-lg-2,
    .dw-xs-3, .dw-sm-3, .dw-md-3, .dw-lg-3,
    .dw-xs-4, .dw-sm-4, .dw-md-4, .dw-lg-4,
    .dw-xs-5, .dw-sm-5, .dw-md-5, .dw-lg-5,
    .dw-xs-6, .dw-sm-6, .dw-md-6, .dw-lg-6,
    .dw-xs-7, .dw-sm-7, .dw-md-7, .dw-lg-7,
    .dw-xs-8, .dw-sm-8, .dw-md-8, .dw-lg-8,
    .dw-xs-9, .dw-sm-9, .dw-md-9, .dw-lg-9,
    .dw-xs-10, .dw-sm-10, .dw-md-10, .dw-lg-10,
    .dw-xs-11, .dw-sm-11, .dw-md-11, .dw-lg-11,
    .dw-xs-12, .dw-sm-12, .dw-md-12, .dw-lg-12,
    .container {
        padding: 0 10px;
    }

    .section {
        margin-right: -10px;
        margin-left: -10px;
    }
}

.dw-xs-1, .dw-xs-2, .dw-xs-3, .dw-xs-4, .dw-xs-5, .dw-xs-6, .dw-xs-7, .dw-xs-8, .dw-xs-9, .dw-xs-10, .dw-xs-11 {
    float: left;
}

.dw-xs-12 {
    width: 100%;
}

.dw-xs-11 {
    width: 91.66666666666666%;
}

.dw-xs-10 {
    width: 83.33333333333334%;
}

.dw-xs-9 {
    width: 75%;
}

.dw-xs-8 {
    width: 66.66666666666666%;
}

.dw-xs-7 {
    width: 58.333333333333336%;
}

.dw-xs-6 {
    width: 50%;
}

.dw-xs-5 {
    width: 41.66666666666667%;
}

.dw-xs-4 {
    width: 33.33333333333333%;
}

.dw-xs-3 {
    width: 25%;
}

.dw-xs-2 {
    width: 16.666666666666664%;
}

.dw-xs-1 {
    width: 8.333333333333332%;
}

@media (min-width: 768px) {
    .container {
        width: 750px;
    }

    .dw-sm-1, .dw-sm-2, .dw-sm-3, .dw-sm-4, .dw-sm-5, .dw-sm-6, .dw-sm-7, .dw-sm-8, .dw-sm-9, .dw-sm-10, .dw-sm-11 {
        float: left;
    }

    .dw-sm-12 {
        width: 100%;
    }

    .dw-sm-11 {
        width: 91.66666666666666%;
    }

    .dw-sm-10 {
        width: 83.33333333333334%;
    }

    .dw-sm-9 {
        width: 75%;
    }

    .dw-sm-8 {
        width: 66.66666666666666%;
    }

    .dw-sm-7 {
        width: 58.333333333333336%;
    }

    .dw-sm-6 {
        width: 50%;
    }

    .dw-sm-5 {
        width: 41.66666666666667%;
    }

    .dw-sm-4 {
        width: 33.33333333333333%;
    }

    .dw-sm-3 {
        width: 25%;
    }

    .dw-sm-2 {
        width: 16.666666666666664%;
    }

    .dw-sm-1 {
        width: 8.333333333333332%;
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px;
    }

    .dw-md-1, .dw-md-2, .dw-md-3, .dw-md-4, .dw-md-5, .dw-md-6, .dw-md-7, .dw-md-8, .dw-md-9, .dw-md-10, .dw-md-11 {
        float: left;
    }

    .dw-md-12 {
        width: 100%;
    }

    .dw-md-11 {
        width: 91.66666666666666%;
    }

    .dw-md-10 {
        width: 83.33333333333334%;
    }

    .dw-md-9 {
        width: 75%;
    }

    .dw-md-8 {
        width: 66.66666666666666%;
    }

    .dw-md-7 {
        width: 58.333333333333336%;
    }

    .dw-md-6 {
        width: 50%;
    }

    .dw-md-5 {
        width: 41.66666666666667%;
    }

    .dw-md-4 {
        width: 33.33333333333333%;
    }

    .dw-md-3 {
        width: 25%;
    }

    .dw-md-2 {
        width: 16.666666666666664%;
    }

    .dw-md-1 {
        width: 8.333333333333332%;
    }
}

@media (min-width: 1230px) {
    .container {
        width: 1230px;
    }

    .dw-lg-1, .dw-lg-2, .dw-lg-3, .dw-lg-4, .dw-lg-5, .dw-lg-6, .dw-lg-7, .dw-lg-8, .dw-lg-9, .dw-lg-10, .dw-lg-11 {
        float: left;
    }

    .dw-lg-12 {
        width: 100%;
    }

    .dw-lg-11 {
        width: 91.66666666666666%;
    }

    .dw-lg-10 {
        width: 83.33333333333334%;
    }

    .dw-lg-9 {
        width: 75%;
    }

    .dw-lg-8 {
        width: 66.66666666666666%;
    }

    .dw-lg-7 {
        width: 58.333333333333336%;
    }

    .dw-lg-6 {
        width: 50%;
    }

    .dw-lg-5 {
        width: 41.66666666666667%;
    }

    .dw-lg-4 {
        width: 33.33333333333333%;
    }

    .dw-lg-3 {
        width: 25%;
    }

    .dw-lg-2 {
        width: 16.666666666666664%;
    }

    .dw-lg-1 {
        width: 8.333333333333332%;
    }
}

@-ms-viewport {
    width: device-width;
}

.visible-xs {
    display: none !important;
}

@media (max-width: 767px) {
    .visible-xs {
        display: block !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .visible-xs.visible-sm {
        display: block !important;
    }
}

@media (min-width: 992px) and (max-width: 1229px) {
    .visible-xs.visible-md {
        display: block !important;
    }
}

@media (min-width: 1230px) {
    .visible-xs.visible-lg {
        display: block !important;
    }
}

.visible-sm {
    display: none !important;
}

@media (max-width: 767px) {
    .visible-sm.visible-xs {
        display: block !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .visible-sm {
        display: block !important;
    }
}

@media (min-width: 992px) and (max-width: 1229px) {
    .visible-sm.visible-md {
        display: block !important;
    }
}

@media (min-width: 1230px) {
    .visible-sm.visible-lg {
        display: block !important;
    }
}

.visible-md {
    display: none !important;
}

@media (max-width: 767px) {
    .visible-md.visible-xs {
        display: block !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .visible-md.visible-sm {
        display: block !important;
    }
}

@media (min-width: 992px) and (max-width: 1229px) {
    .visible-md {
        display: block !important;
    }
}

@media (min-width: 1230px) {
    .visible-md.visible-lg {
        display: block !important;
    }
}

.visible-lg {
    display: none !important;
}

@media (max-width: 767px) {
    .visible-lg.visible-xs {
        display: block !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .visible-lg.visible-sm {
        display: block !important;
    }
}

@media (min-width: 992px) and (max-width: 1229px) {
    .visible-lg.visible-md {
        display: block !important;
    }
}

@media (min-width: 1230px) {
    .visible-lg {
        display: block !important;
    }
}

.hidden-xs {
    display: block !important;
}

@media (max-width: 767px) {
    .hidden-xs {
        display: none !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .hidden-xs.hidden-sm {
        display: none !important;
    }
}

@media (min-width: 992px) and (max-width: 1229px) {
    .hidden-xs.hidden-md {
        display: none !important;
    }
}

@media (min-width: 1230px) {
    .hidden-xs.hidden-lg {
        display: none !important;
    }
}

.hidden-sm {
    display: block !important;
}


@media (max-width: 767px) {
    .hidden-sm.hidden-xs {
        display: none !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .hidden-sm {
        display: none !important;
    }
}

@media (min-width: 992px) and (max-width: 1229px) {
    .hidden-sm.hidden-md {
        display: none !important;
    }
}

@media (min-width: 1230px) {
    .hidden-sm.hidden-lg {
        display: none !important;
    }
}

.hidden-md {
    display: block !important;
}

@media (max-width: 767px) {
    .hidden-md.hidden-xs {
        display: none !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .hidden-md.hidden-sm {
        display: none !important;
    }
}

@media (min-width: 992px) and (max-width: 1229px) {
    .hidden-md {
        display: none !important;
    }
}

@media (min-width: 1230px) {
    .hidden-md.hidden-lg {
        display: none !important;
    }
}

.hidden-lg {
    display: block !important;
}


@media (max-width: 767px) {
    .hidden-lg.hidden-xs {
        display: none !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .hidden-lg.hidden-sm {
        display: none !important;
    }
}

@media (min-width: 992px) and (max-width: 1229px) {
    .hidden-lg.hidden-md {
        display: none !important;
    }
}

@media (min-width: 1230px) {
    .hidden-lg {
        display: none !important;
    }
}


.carousel__inner > .item {
    position: relative;
    display: none;
    -webkit-transition: .6s ease-in-out left;
    -o-transition: .6s ease-in-out left;
    transition: .6s ease-in-out left;
}

.carousel__inner > .item > img, .carousel__inner > .item > a > img {
    line-height: 1;
}

.carousel__inner > .active, .carousel__inner > .next, .carousel__inner > .prev {
    display: block;
}

.carousel__inner > .active {
    left: 0;
}

.carousel__inner > .next, .carousel__inner > .prev {
    position: absolute;
    top: 0;
    width: 100%;
}

.carousel__inner > .next {
    left: 100%;
}

.carousel__inner > .prev {
    left: -100%;
}

.carousel__inner > .next.left, .carousel__inner > .prev.right {
    left: 0;
}

.carousel__inner > .active.left {
    left: -100%;
}

.carousel__inner > .active.right {
    left: 100%;
}

.carousel__indicators {
    position: absolute;
    z-index: 99999999999;
    bottom: 20px;
    width: 100%;
    padding: 0;
    margin: 0;
    text-align: center;
    list-style: none;
}

.carousel__indicators li {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 2px;
    text-indent: -999px;
    cursor: pointer;
    background-color: $color-white;
    border-radius: 10px;
}

.carousel__indicators .active {
    background-color: $color-primary;
}

.carousel__caption {
    width: 100%;
    margin-top: 110px;
    z-index: 10;
    color: $color-white;
    padding: 0 15px;

    @include media('<=phone') {
        top: 55px;
    }
}


.carousel__caption {
    @include media('>desktop') {
        width: 1230px;
    }

    @include media('<desktop','>tablet') {
        max-width: 970px;
    }

    @include media('<=tablet','>phone') {
        max-width: 750px;
    }

    @include media('<=phone') {
        padding: 0 10px;

        .carousel__caption span {
            display: none;
        }
    }
}

﻿﻿﻿﻿.navigation {
    background-color: $color-second;

    .container {
        @include media('<=tablet') {
            position: relative;
        }
    }

    @include media('<=tablet') {
        background-color: $color-white;
    }
}


﻿﻿﻿.navigation.nav--shut {
    @include media('<=tablet') {
        height: 0;
        overflow: hidden;
    }
}

.nav__item--home {
    @include media('>tablet') {
        width: 6% !important;
    }

    @include media('<desktop','>tablet') {
        width: 5% !important;
    }

    &:hover {
        color: $color-white;
        background-color: $color-primary;
    }
}

.nav__item:nth-child(4), .nav__item:nth-child(6), .nav__item:nth-child(7) {
    @include media('>tablet') {
        width: 11%;
    }
}

i.arrow--desk {
    margin-left: 5px;
    font-size: 1em;
    height: 50px;
    line-height: 50px;
    position: absolute;
    color: $color-white;

    @include media('<=tablet') {
        display: none;
    }

    @include media('<desktop','>tablet') {
        margin-left: 2px;
    }
}

.nav--shut {
    @include media('<=tablet') {
        list-style: none;
        margin: 0;
        padding: 0;
    }
}

.nav__sub.nav--shut {
    @include media('<=tablet') {
        height: 0;
        overflow: hidden;
    }
}

.nav__item.offers {
    @include media('<=tablet') {
        .arrow--item {
            display: none;
        }
    }
}

.navigation .container {
    @include media('>tablet') {
        position: relative;
    }

    @include media('<=tablet') {
        padding: 0;
    }
}

.nav {
    @include media('>tablet') {
        list-style: none;
        margin: 0;
        padding: 0;
    }
}

.nav__sub--links {
    @include media('>tablet') {
        width: 16.3%;
    }
}

.nav--links.on {
    @include media('>tablet') {
        color: $color-primary;
        background-color: $color-white;
    }
}

.nav__item {
    @include media('>tablet') {
        float: left;
        width: 15%;
        line-height: 50px;
        text-align: center;
        margin-right: 1px;

        &:hover .nav__sub {
            visibility: visible;
            overflow: visible;
            height: auto;
        }

        &:hover .greyblock {
            height: 5px;
            visibility: visible;
        }

        &:hover .nav--links {
            color: $color-primary;
            background-color: $color-white;
        }

        &:hover i.arrow--desk {
            color: $color-primary;
        }
    }

    @include media('<desktop','>tablet') {
        font-size: 0.8em;
    }
}

.nav__item:nth-child(2), .nav__item:nth-child(7), .nav__item:nth-child(6) {
    .nav__sub {
        @include media('>tablet') {
            max-width: 600px;
        }
    }

    .nav__sub--list {
        width: 100%;
    }

    .nav__gift {
        margin-top: 35px;

        img {
            right: auto;
        }


        .nav__gift--text {
            span {
                display: none;
            }
        }
    }
}

.nav__item:nth-child(6) {
    @include media('>tablet') {
        ul {
            padding-bottom: 3px;
        }
    }
}

.nav__item:nth-child(2) {
    .nav__gift {
        margin-top: 70px;
    }
}

.nav__item:nth-child(3), .nav__item:nth-child(5) {
    .nav__gift {
        margin-top: 0;
    }
}

.nav__item:nth-child(7) {
    .nav__gift {
        margin-top: 70px;
    }
}

.nav__item:nth-child(7), .nav__item:nth-child(6) {
    .nav__sub {
        left: 50%;

        @include media('<desktop','>tablet') {
            left: 35%;
        }
    }
}


.nav__item:nth-child(4) {
    .nav__sub {
        @include media('>tablet') {
            max-width: 900px;
        }
    }

    .nav__sub--list {
        @include media('>tablet') {
            width: 34%;
        }
    }

    .nav__gift {
        margin-top: 70px;

        img {
            max-width: 188px;
            top: -8px;
            right: auto;
        }

        .btn {
            max-width: 200px;
        }

        .nav__gift--text {
            .inside--cap {
                display: none;
            }

            span {
                margin-right: 10px;
            }

            width: 90%;
            text-align: right;
        }
    }
}

.nav__item:nth-child(4) {
    .nav__sub {
        left: 5%;
    }
}

.nav__item:nth-child(5) {
    .nav__sub {
        left: 31.66%;

        @include media('>tablet') {
            max-width: 535px;
        }
    }

    .nav__sub--list {
        width: 100%;
    }


    .nav__gift {
        img {
            right: auto;
        }


        .nav__gift--text {
            span {
                display: none;
            }
        }
    }
}

.nav__item:last-child {
    .nav__sub {
        right: 20px;
        left: auto;

        @include media('>tablet') {
            max-width: 800px;
        }
    }

    .nav__sub--list {
        @include media('>tablet') {
            width: 34%;
        }
    }

    .nav__gift {
        margin-top: 70px;
    }
}

.nav--links {
    @include media('>desktop') {
        text-align: left;
        padding: 0 20px;
    }

    @include media('>tablet') {
        font-weight: bold;
        display: block;
        clear: both;
        color: $color-white;
        font-size: 0.95em;
    }

    @include media('<=tablet') {
        height: 50px;
        line-height: 50px;
        border-bottom: 1px solid $color-second;
        width: 90%;
        float: left;
        padding: 0 15px;
        font-weight: 700;
        color: $color-primary;
    }
}


.nav--links-home {

    @include media('<=tablet') {
        width: 100%;
    }
}

.arrow--item {
    @include media('<=tablet') {
        width: 10%;
        float: right;
        height: 50px;
        line-height: 50px;
        margin: auto;
        text-align: center;
        border-bottom: 1px solid $color-primary;
        font-size: 1.5em;
        color: $color-primary;
        padding: 15px 0;
    }

    @include media('<=phone') {
        font-size: 1.2em;
    }
}

.nav__sub--list {
    list-style: none;
    text-align: left;
    line-height: 35px;
    width: 25.5%;
    float: left;


    i {
        font-size: 0.7em;
        margin-left: 5px;

        @include media('<=tablet') {
            float: right;
            font-size: 1.5em;
            margin: 8px 15px auto;
        }

        @include media('<=phone') {
            font-size: 1.1em;
            margin: 8px -2px auto;
        }
    }

    @include media('<=tablet') {
        width: 100%;
        float: none;
    }
}

.nav--sub--links {
    color: $color-primary;
    font-weight: 700;
    display: block;

    &:hover {
        color: $color-second;
    }

    @include media('<=tablet') {
        display: block;
        width: 100%;
        border-bottom: 1px solid $color-primary;
        font-weight: 400;
        padding: 0 15px;
    }

    span {
        display: block;
        font-style: italic;
        margin-top: -15px;
        font-size: 0.9em;
        color: $color-orange;
    }
}

.nav--arrow i.arrow--item {
    @include media('>tablet') {
        display: none;
    }
}

.nav__item:nth-child(2) .nav__sub--items:nth-child(4) {
    @include media('>tablet') {
        margin-top: 20px;
    }
}

.nav__sub {
    box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.17);

    @include media('>tablet') {
        visibility: hidden;
        position: absolute;
        width: 100%;
        height: 0;
        left: 0%;
        background-color: $color-white;
        z-index: 9999;
        margin-top: 5px;
        border-radius: 2px;
        min-height: 320px;
    }

    img {
        position: absolute;
        top: 15px;
        right: 15px;

        @include media('<=tablet') {
            position: relative;
            margin: 20px auto;
            display: block;
            right: auto;
            top: auto;
        }
    }
}

.nav--links--on {
    @include media('>tablet') {
        background-color: $color-white;

        a, i.arrow--desk {
            color: $color-primary;
        }
    }
}

.nav--fix {
    @include media('>tablet') {
        position: relative;
        display: block;
    }

    a {
        img {
            @include media('>tablet') {
                display: none;
            }

            @include media('<=tablet') {
                display: block;
                float: left;
                margin-right: 15px;
            }
        }
    }
}

.nav__sub--items {

    @include media('>tablet') {
        height: auto;
        display: block;
        padding: 25px 25px;
    }

    @include media('<=tablet') {
        background-color: $color-white;
        padding: 0;
    }
}

.greyblock {
    @include media('>tablet') {
        position: absolute;
        bottom: -5px;
        width: 100%;
        left: 0;
        visibility: hidden;
        height: 0px;
        z-index: 9999;
        background-color: $color-white;
    }
}

.nav__gift {
    position: relative;

    img {
        top: -30px;
        right: auto;
    }

    @include media('<=tablet') {
        display: none;
    }
}

.nav__gift--text {
    width: 80%;
    background-color: $color-navy;
    color: $color-white;
    padding: 5px;
    font-size: 1.2em;
    font-weight: 700;
    text-align: center;
    float: right;

    .btn {
        float: right;
        max-width: 260px;
        margin: 5px;
        font-weight: normal;
    }
}

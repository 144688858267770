﻿.browser {
    background-color: $color-second;
    border-top: 5px solid $color-primary;

    a {
        display: block;
        text-align: center;
    }
}

.browser__content {
    background-color: $color-primary;

    @include media('>tablet') {
        height: 100vh;
    }
}

.no__coral {
    background-color: $color-white;
    margin: 50px auto;
    max-width: 450px;
    text-align: center;
    padding: 15px;
}

#coralfinder {
    max-width: 835px;
    margin: auto;

    .KO {
        max-width: 450px;
        margin: 10px auto;
        font-size: 1.2em;
        font-weight: 600;
    }

    .field-validation-error {
        text-align: center;
        display: block;
        background-color: $color-white;
        font-size: 1.2em;
        font-weight: 600;
        max-width: 450px;
        margin: 10px auto;
        padding: 10px;
    }

    h1 {
        color: $color-white;
        text-align: center;
        margin: 40px auto 20px;
        display: block;
        @include primary-font-stack;
        font-size: 2.2em;
    }

    input {
        height: 60px;
        line-height: 60px;
        max-width: 450px;
        margin: auto;
        display: block;
        border-radius: 2px;
        font-size: 1.2em;
        text-align: center;
    }

    .btn {
        height: 75px;
        line-height: 75px;
        max-width: 450px;
        margin: 30px auto 0;
        font-size: 1.2em;
        font-weight: 700;
    }
}

.browser--info {
    color: $color-white;
    display: block;
    text-align: center;
    max-width: 365px;
    margin: auto auto 50px;
}

.browser--bg {
    background: url('../img/coral-browser.jpg') no-repeat;
    height: 783px;
    background-position: center bottom;
    position: absolute;
    width: 100%;
    top: 35%;
    z-index: -1;

    @include media('>desktop') {
        background-size: 100% 100%;
    }

    @include media('<=desktop') {
        background-size: cover;
    }
}

.browser__finder {
    background-color: $color-white;
    border-radius: 2px;
    margin: 80px auto 0;
    max-width: 660px;
    padding: 50px 15px 15px;
    position: relative;
    .dw-xs-5 {
        padding-right: 0;
    }

    .dw-xs-7 {
        padding-left: 0;
    }

    @include media('<=phone') {
        margin-bottom: 35px;
    }

    h2 {
        color: $color-dark;
        font-size: 1.25em;
        padding: 10px 4px;
        font-weight: 900;
    }
}

.browse--wysiwyg {
    font-weight: 700;
    display: block;
    color: $color-white;
    background-color: $color-primary;
    max-width: 110px;
    padding: 5px;
    margin: 10px 5px;
    text-align: center;
}

.browser--price {
    display: block;
    background-color: $color-browse-now;
    padding: 15px;

    .was {
        color: $color-grey;
        font-size: 1.2em;
        margin-right: 10px;

        .line {
            text-decoration: line-through;
            font-weight: 700;
        }
    }

    .now {
        color: $color-primary;
        font-size: 1.2em;
        font-weight: 700;

        .big {
            font-weight: 900;
            font-size: 1.5em;
        }
    }
}

.browser--number {
    width: 105px;
    height: 105px;
    line-height: 105px;
    background-color: $color-browse-black;
    color: $color-browse-number;
    border-radius: 100%;
    font-size: 2em;
    text-align: center;
    @include secondary-font-stack;
}

.browse--title {
    color: $color-dark-grey;
    display: block;
    margin-top: 15px;
}

.browse--name {
    color: $color-primary;
    font-size: 1.4em;
    font-weight: 700;
}

.browse--close {
    right: 0;
    top: 0;
    padding: 15px;
    text-transform: uppercase;
    color: $color-dark-grey;
    font-weight: 700;
    margin-right: 15px;
    cursor: pointer;
    position: absolute;

    i {
        color: $color-primary;
        margin-left: 15px;
    }
}
﻿.account {
	padding-bottom: 60px;
	background-color: $color-baby-blue;

	h1 {
		color: $color-primary;
		margin-bottom: 40px;
		text-align: center;

		 @include media('<=phone') {
			 font-size: 1.6em;
	     }
	}

    .manage--save {
        h1{
            font-size:1.7em;
            text-align:left;
            i{
                color: $color-orange;
                margin-right:10px;
            }
            border-bottom:1px solid $color-baby-blue;
            padding-bottom:15px;
            margin-bottom:20px;
        }

        .save-list--basket{
            margin-top:20px;
            border:1px solid $color-baby-blue;
            padding:20px;

			.OK {
				margin-top: 20px;
			}

            .dw-md-4 img{ border:1px solid $color-baby-blue; }
            .dw-xs-4{  
                @media screen and (max-width:370px) {
                    display:none;
                }
            }
            .dw-md-2 img{ margin-top:40px; }
            .dw-md-2{
                @media screen and (max-width:620px) {
                    display:none;
                }
            }
            .dw-xs-6{
                @media screen and (max-width:620px) {
                    width:66.66%;
                }
                @media screen and (max-width:370px) {
                    width:100%;
                }
            }
            .dw-md-6 span.prod--code{ 
                display:block; margin-top:40px; color: $color-primary; 
                @media screen and (max-width:550px) {
                    margin-top:15px;
                }
            }
            .dw-md-6 .prod--title{
                font-size:1.3em;
            }
            .dw-xs-5{
                @media screen and (max-width:620px) {
                    width:100%;
                }
            }
            .prod--price{
                display: block;
                color: $color-primary;
                font-size: 1.5em;
                font-weight: 900;
                text-align:center;
                margin-top:40px;
                @media screen and (max-width:620px) {
                    margin-top:10px;
                }
            }
            .basket--info{
                span{ color: $color-dark; display:block; float:left; font-weight:bold; margin-top:40px;}
                input[type="number"]{ width:50px; display:inline-block; float:left; height:40px; margin: 27px 15px 0 10px; }
                .btn--orange{ float:left; height:40px; line-height:40px; width:160px; font-size:0.9em; margin-top: 27px; 
                    @include media('<desktop','>tablet') {
			            width:124px;
		            }
                    @media screen and (max-width:370px) {
                    clear:both;
                   }
                }
                a{ float:right; width:auto; margin-top:40px; color: $color-dark; display:block; 
                   @media screen and (max-width:620px) {
                    float:left; margin-left:15px;
                   }
                }
                @media screen and (max-width:620px) {
                    width:100%;
                }
            }
            @media screen and (max-width:550px) {
                    padding:15px;
            }
        }
    }

	.reward--points {
		background-color: $color-baby-blue;

		img {
			margin-right: 15px;
		}

		h3 {
			line-height: 40px;
		}

		.rewards--block {
			background-color: $color-white;
			padding: 10px;
			margin: 3px 0;
			span {
				font-weight: 600;
			}

			.reward--amount {
				color: $color-orange;
				display: inline-block;
				margin-left: 5px;
			}
		}
	}
}
.account.empty--basket {
	margin-bottom: 250px;

	h2 {
		text-align: center;
	}

	.section {
		max-width: 450px;
		margin: auto;
	}
}

.retrieve {
	.btn {
		margin-top: 40px!important;
	}
}


.account--payment {
	h1 {
		@include media('<=phone') {
			font-size: 1.65em;
		}
	}

	.checkout--heading {
		margin-top: 15px;

		i {
			margin-left: 0;
		}

		h2 {
			display: inline-block;

			@include media('<=phone') {
				font-size: 1.2em;
			}
		}
	}
}

.email-center {
	width: 100%;
	max-width: 800px;
	background-color: #fff;
	margin: 0 auto 20px;
}
.account--white {
	background-color: $color-white;
}

.manage--save.account__content{
    padding:20px;
}
.account__content {
	border-radius: 2px;
	padding: 35px;
	margin-bottom: 10px;

	.editor-label {
		margin: 10px 0;
		color: $color-dark-grey;
		font-weight: 700;

		input.check-box {
			width: 25px;
			height: 25px;
			float: left;
			margin-right: 10px;
		}

		a {
			color: $color-orange;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}

    .save-list-item{
        text-align:center; 
        border: 1px solid #d5d5d5;
        min-height:120px;
        position:relative;
        margin-bottom:15px;
        i{ display:block; color:$color-orange; font-size:2.2em; }
        .inner{
            text-align: center; top: 50%; left: 50%; position: absolute; transform: translate(-50%,-50%); -webkit-transform: translate(-50%,-50%); -moz-transform: translate(-50%,-50%); -o-transform: translate(-50%,-50%); -ms-transform: translate(-50% -50%); width: 75%;
        }
        h3{ margin-top:10px; }
        
    }

    .save-list--btns{
        margin:0 -10px;
        .dw-md-6{
            padding:0 10px;
        }
        .btn{ font-size:1em; height:40px; line-height:40px; }
        .btn--baby-blue{
            @include media('<=tablet') {
                 margin-top:10px;
            }
        }
    }

	.editor-label.sp {
		position: relative;

		.forgot {
			color: $color-primary;
			position: absolute;
			right: 15px;
			top: -64px;
			cursor: pointer;

			&:hover {
				color: $color-orange;
			}

			i {
				font-size: 1em;
			}
		}
	}

	.editor-label.agree {
		line-height: 30px;
		font-weight: normal;
		margin-bottom: 20px;
	}

	.editor-label.login {
		font-weight: normal;
		text-align: center;
		margin-top: 25px;
	}

	.editor-field {
		input {
			height: 50px;
			line-height: 50px;
			font-weight: 700;
			border-radius: 2px;
		}
	}

	.btn {
		height: 40px;
		line-height: 40px;
		font-weight: 700;
		font-size: 1em;
		margin-top: 15px;
	}
}

.account--sub-title {
	color: $color-primary;
	margin: 20px 0;
}

.account__title {
	margin: auto auto 30px;
	text-align: center;

	i {
		color: $color-orange;
		vertical-align: middle;
		font-size: 3em;
		margin-right: 15px;
	}

	h2 {
		display: inline-block;
		vertical-align: middle;
		color: $color-primary;

		@include media('<=phone') {
			font-size: 1.2em;
		}
	}
}

.account--info {
	color: $color-dark-grey;
	font-weight: 700;
	text-align: center;
	margin-bottom: 20px;
	display: block;
}
.forgot {
	text-align: right;
	display: block;
	margin-top: 20px;

	a {
		color: $color-orange;
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
}

.account__why {
	margin: 28px auto;
	text-align: center;

	h2 {
		color: $color-primary;
		margin-bottom: 30px;

		@include media('<=phone') {
			margin-bottom: 10px;
		}
	}

	@include media('<=phone') {
		margin: 20px auto 0;
	}
}

.account__express {
	margin: 10px auto 10px;
	text-align: center;

	i {
		color: $color-orange;
		vertical-align: middle;
		font-size: 3em;
		margin-right: 15px;
	}

	h2 {
		display: inline-block;
		vertical-align: middle;
		color: $color-primary;

		@include media('<=phone') {
			font-size: 1.2em;
		}
	}
}

.account__register {
	margin: 30px 0 8px;

	@include media('<=phone') {
		margin: 10px 0 8px;
		padding-bottom: 25px;
	}

	span {
		color: $color-dark-grey;
		font-weight: 700;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 75px;
		text-align: left;
		font-size: 0.85em;

		@include media('<=phone') {
			top: 65px;
			left: 50%;
			transform: translate(-50%,0%);
			text-align: center;
		}
	}
}

.account__why--points {
	position: relative;
	width: 100%;
	height: 60px;
}

.account__why--circle {
	width: 60px;
	height: 60px;
	line-height: 60px;
	text-align: center;
	margin: auto;
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	vertical-align: middle;
	background-color: $color-orange;
	border-radius: 50%;
	color: $color-white;
	font-size: 2em;

	@include media('<=phone') {
		top: 0;
		transform: translate(-50%,0%);
		left: 50%;
	}
}

.register--account {
	max-width: 580px;
	margin: auto;
}


.account-menu {
	.section {
		@include media('<tablet','>phone') {
			.dw-lg-4:nth-child(3n+1) {
				clear: both;
			}
		}
		@include media('<=phone') {
			.dw-lg-4:nth-child(2n+1) {
				clear: both;
			}
		}
	}

	strong {
		color: $color-primary;
		display: block;
		text-align: center;
	}

	i {
		display: block;
		text-align: center;
		height: 40px;
		line-height: 40px;
		margin-bottom: 10px;
		font-size: 2.8em;
		color: $color-orange;
	}

	a {
		padding: 15px;
		display: block;
		margin-top: 15px;
		border: 1px solid $color-border-lgrey;

		&:hover strong {
			color: $color-second;
		}
	}
}

.account__address {
	border-radius: 2px;
	background-color: $color-baby-blue;
	border: 1px solid $color-border-lgrey;

	@include media('>phone') {
		padding: 30px;
		margin: 30px 0;
	}

	.btn {
		margin: 10px 0;
	}

	.dw-xs-12 {
		.btn  {
			max-width: 215px;
		}
	}
}

.account__background {
	background-color: $color-white;
	padding: 15px;
	margin-bottom: 15px;
}

.account__order-history {
	margin-top: 30px;
}

.account__orders {
	margin-top: 30px;
	line-height: 20px;
}

.account__orders--row.orders .row:first-child {
	font-weight: 700;
	border-top: 0px;
}

.account__orders--row {
	border-top: 1px solid #d7d7d7;
}

.account__orders--row.head{
    font-weight:bold;
    color: $color-primary;
}

.account__orders--row > div {
	float: left;
	padding: 5px 10px;
}

.account__orders--details {
	@include media('>tablet') {
		width: 32%;
	}

	@include media('<=tablet', '>phone' ) {
		width: 40%;
	}
}

.account__orders--products {
	position: relative;
	padding-right: 50px;
    a strong{
        color: $color-orange;
    }
	@include media('>tablet') {
		width: 41%;
	}

	@include media('<=tablet', '>phone' ) {
		width: 26%;
	}
}

.account-menu{
    .dw-lg-3:nth-child(2n+1){
        @include media('<=phone') {
		    clear:both;
	    }
    }

}

.account__orders--cost {
	.btn {
		margin: 10px auto;
	}
	@include media('>tablet') {
		width: 27%;
	}

	@include media('<=tablet', '>phone' ) {
		width: 34%;
	}
}

.non-login .form-surround {
	max-width: 445px;
	margin: 0 auto;
}

.non-login .form-surround .btn {
	margin-top: 15px;
	margin-bottom: 25px;
}

.account .field-validation-error {
	color: #e11717 !important;
	padding: 0;
	font-size: .8em;
	display: block;
}

.account .non-login .form-surround {
	padding-bottom: 23px;
}

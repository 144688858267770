﻿@import 'global/vars';
@import 'global/typography';
@import 'vendor/includemedia';
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-size: 1em;
    color: $color-dark;
    background-color: $color-white;
    margin: 0;
    padding: 0;
    -webkit-text-size-adjust: 100%;
}

img {
    border: 0;
    max-width: 100%;
    height: auto;
    display: block;
}

.breadcrumb {
    font-size: .7em;
    margin: 15px 0 25px;
    color: $color-dark-grey;

    a {
        color: $color-dark-grey;

        &:hover {
            color: $color-primary;
        }
    }
}
.home--heading {
    text-align: center;
    margin-top: 20px;

    @include media('<=phone') {
        font-size: 1.6em;
    }
}

.home--text {
    text-align: center;
    display: block;
    margin-top: 15px;
}

h1 {
    font-size: 2em;
    color: $color-primary;
}

h2 {
    font-size: 1.6em;
    color: $color-primary;
}

h3 {
    font-size: 1.3em;
    color: $color-primary;
}

.g-recaptcha div, .g-recaptcha {
    height: 50px;
    max-width: 302px;
    margin: auto;
}

.g-recaptcha div {
    margin-top: -14px;
}

.g-recaptcha {
    border-right: 1px solid #d3d3d3;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    overflow: hidden;
    border-bottom: 1px solid #d3d3d3;
    border-top: 1px solid #d3d3d3;
    border-bottom-left-radius: 3px;
    margin-top: 0px;
}

button {
    outline: 0;
}

input[type="submit"], input[type="button"] {
    -webkit-appearance: none;
    cursor: pointer;
    text-align: center;
    line-height: 40px;
    height: 40px;
}

input[type="submit"], input[type="text"], input[type="password"] {
    -webkit-appearance: none;
}

.btn {
    cursor: pointer;
    width: 100%;
    line-height: 40px;
    height: 40px;
    text-align: center;
    background-color: #215293;
    color: #fff;
    border: 1px solid #215293;
    font-size: 1em;
    display: block;
    border-radius: 2px;
    @include primary-font-stack;
}

.btn--blue {
    background-color: $color-primary;
    border-color: $color-primary;
    color: $color-white;

    &:hover {
        background-color: $color-second;
        border-color: $color-second;
    }
}

.btn--collect {
    background-color: $color-tabs;
    border-color: $color-tabs;
    color: $color-dark;
    text-transform: none !important;
    cursor: initial;
    position: relative;

    @include media('<=tablet') {
        line-height: 13px !important;
        padding: 15px 0;
    }

    span {
        display: inline-block;
        font-size: 0.85em;
        margin-right: 45px;

        @include media('<=tablet') {
            font-size: 0.7em;
        }
    }

    img {
        position: absolute;
        right: 85px;
        top: 10px;
        width: 35px;

        @include media('<desktop','>tablet') {
            right: 15px;
        }

        @include media('<=tablet') {
            right: 15px;
        }

        @include media('<=phone') {
            right: 15px;
        }
    }
}


.btn--baby-blue {
    background-color: $color-baby-blue;
    border-color: $color-baby-blue;
    color: $color-second;

    &:hover {
        background-color: $color-second;
        border-color: $color-second;
        color: $color-white;
    }
}

.btn--orange {
    background-color: $color-orange;
    border-color: $color-orange;
    color: $color-white;

    &:hover {
        background-color: $color-dark-orange;
        border-color: $color-dark-orange;
    }
}

.btn--right {
    float: right;
}
.btn--left {
    float: left;
}

.btn--size {
    max-width: 200px;
    margin-top: 30px;

    @include media('<=phone') {
        max-width: none;
    }
}


input[type="text"], input[type="password"], input[type="tel"], input[type="number"] {
    width: 100%;
    height: 40px;
    padding: 0 0 0 10px;
    border: none;
    color: #777;
    border: 1px solid #d4d4d4;
    border-radius: 0;
}

textarea {
    border: 1px solid #d4d4d4;
    padding: 10px 0 0 10px;
    color: #777;
    min-height: 70px;
    border-radius: 0;
    width: 100%;
}

select {
    background-color: #fff;
    width: 100%;
    height: 40px;
    line-height: 40px;
    border: none;
    color: #777;
    border: 1px solid #d4d4d4;
    border-radius: 0;
    padding: 0 0 0 5px;
}

textarea:focus, input:focus, select {
    outline: none;
}

#content {
    position: relative;
    z-index: 999;
}

.rewards {
    background: url('../img/rewards-bg.jpg') no-repeat center;
    background-size: cover;
    height: auto;
    color: $color-white;
    padding: 30px 0 35px;

    h2 {
        color: $color-white;
        margin: 20px 0;
    }

    .btn {
        margin: 20px 0;
        max-width: 220px;
        font-weight: 600;
    }

    .dw-xs-4 {
        @include media('<=375px') {
            width: 50%;
        }
    }

    .dw-xs-4:nth-child(3) {
        @include media('<=375px') {
            margin: 0 25%;
        }
    }

    .reward--circle {
        background-color: $color-orange;
        border-radius: 50%;
        width: 90px;
        height: 90px;
        margin: auto;
        position: relative;
        z-index: 9;

        @include media('<=375px') {
            margin-top: 15px;
        }

        i {
            text-align: center;
            margin: auto;
            display: block;
            line-height: 90px;
            font-size: 2.8em;
        }
    }

    .reward--title {
        text-transform: uppercase;
        text-align: center;
        display: block;
        margin-top: 15px;
        font-weight: 900;
        font-size: 1.2em;
    }

    .reward--info {
        display: block;
        text-align: center;
        margin-top: 10px;
    }

    .reward--line {
        &:after {
            content: "";
            height: 10px;
            background-color: $color-white;
            display: block;
            position: absolute;
            top: 50%;
            left: 99%;
            width: 500px;
            -ms-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);

            @include media('<desktop','>tablet') {
                width: 355px;
            }

            @include media('<=phone') {
                width: 400px;
            }

            @include media('<=600px') {
                width: 265px;
            }

            @include media('<=375px') {
                width: 95px;
            }
        }
    }
}

a {
    text-decoration: none;
}
.product__calculator {
    display: none;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    background-color: $color-white;
    overflow-x: hidden;
    transition: 0.5s;
    max-width: 390px;
    box-shadow: 0px 0px 15px $color-black;
}

.calc--line {
    border: 1px solid $color-border-lgrey;
    display: block;
    margin-bottom: 20px;
}

.calc--line.bottom {
    margin-top: 20px;
}

.calc--title {
    color: $color-primary;
    font-size: 1.8em;
    font-weight: 900;
    margin-bottom: 20px;

    i {
        color: $color-orange;
        margin-right: 10px;
    }
}

.cal--overlay.hide {
    position: initial!important;
}

.cal--overlay.show {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.calc__forms {
    padding: 50px 45px;

    h2 {
        font-weight: 900;
        margin-bottom: 20px;
        color: $color-second;
    }

    input[type=text] {
        font-weight: 900;
    }

    .editor-label {
        font-weight: 600;
        margin: 10px 0;
    }

    .editor-field {
        margin-bottom: 10px;
    }

    .btn {
        margin-top: 15px;
        text-transform: uppercase;
        font-weight: 900;
    }
}

.calculator__total {
    background-color: $color-baby-blue;
    padding: 15px 0;
    margin-top: 20px;

    .section {
        margin: 0;
    }
}

.calc--total {
    color: $color-primary;
    font-weight: 900;
    font-size: 1.2em;
    display: block;
}

.calc--amount {
    font-weight: 900;
    color: $color-orange;
    font-size: 1.4em;
    display: flex;
    justify-content: flex-end;
}

.calc--reset {
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: underline;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: 15px;

    &:hover {
        color: $color-primary;
    }
}

.calc--slide-in {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
    display: block;
}

.calc--slide-out {
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
}

.calc--close {
    font-size: 1.5em;
    float: right;
    padding: 30px;
    color: $color-dark-grey;
    cursor: pointer;

    &:hover {
        color: $color-primary;
    }
}

@keyframes slide-in {
    0% {
        -webkit-transform: translateX(-100%);
    }

    100% {
        -webkit-transform: translateX(0%);
    }
}

@-webkit-keyframes slide-in {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0%);
    }
}

@keyframes slide-out {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(-110%);
    }
}

@-webkit-keyframes slide-out {
    0% {
        -webkit-transform: translateX(0%);
    }

    100% {
        -webkit-transform: translateX(-100%);
    }
}

/*Error Validation*/
.OK {
    padding: 10px 0;
    color: $color-white;
    margin-bottom: 10px;
    text-align: center;
    background-color: $color-primary;
}

#atbMessage {
    margin-top: 20px;
    max-width: 480px;

    .OK {
        background-color: $color-primary;
        padding: 15px;
    }
}

.OK ul {
    padding: 0;
    margin: 0;
    list-style: none;
    color: $color-white;
}

.view-basket {
    color: $color-white;
}

.KO {
    padding: 10px;
    color: $color-white;
    background-color: $color-red;
    margin-bottom: 10px;
    text-align: left;
    a{ color:#fff; font-weight:bold; }
}

.KO ul {
    padding: 0;
    margin: 0;
    list-style: none;
    color: $color-white;
}

.Generic {
    padding: 10px;
    color: $color-white;
    background-color: $color-black;
    margin-bottom: 10px;
}

.field-validation-error {
    color: $color-red !important;
    font-size: 0.8em;
    line-height: normal;
    line-height: initial;
    height: auto;
    padding: 5px;
    margin: 5px 0;
    display: block;
    background-color: $color-white;
}

.validation-summary-errors ul, .validation-summary-errors ol {
    color: #e91e63;
    margin-bottom: 15px;
}

.ui-autocomplete {
    background-color: #f9f9f9;
    width: 420px !important;
    padding: 0;
    margin: 0;
    list-style: none;
    z-index: 99999;
    border-radius: 2px;
}

.ui-menu-item {
    color: $color-black;
    background-color: #f9f9f9;
    cursor: pointer;
}

.ui-menu-item a {
    color: $color-black;
    display: block;
    padding: 10px 15px;
    background-color: #f9f9f9;
}

.ui-menu-item a:hover {
    background-color: $color-baby-blue;
    color: $color-primary;
    font-weight: 600;
}

.ui-helper-hidden-accessible {
    display: none;
}

.outside i {
    display: none;
}

.background-lazy {
    position: relative;
    background-color: transparent;
    background-image: none !important;
}

.background-lazy:before {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    display: inline-block;
    color: #fff;
    font-size: 5px;
    margin: 0;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    -o-animation: load4 1.3s infinite linear;
    -moz-animation: load4 1.3s infinite linear;
    -webkit-animation: load4 1.3s infinite linear;
    animation: load4 1.3s infinite linear;
    content: "";
}

.ajaxLoader {
    min-height: 40px;
    line-height: 35px;
    text-align: center;
}

.ajaxLoader i {
    display: inline-block;
    color: #215293;
    font-size: 5px;
    margin: 0;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    -o-animation: load4 1.3s infinite linear;
    -moz-animation: load4 1.3s infinite linear;
    -webkit-animation: load4 1.3s infinite linear;
    animation: load4 1.3s infinite linear;
    content: "";
}

#preview {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99999;
    display: none;
}

#preview .overlay {
    background-color: #000;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6
}

#preview .popup-view {
    position: relative;
    width: 100%;
    max-width: 1040px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding: 15px;
    max-height: 101%;
    overflow-y: auto;
}

#preview .form-surround {
    position: relative;
}

#preview .form-surround {
    background-color: #fff;
}

#preview i.fa {
    width: 30px;
    text-align: center;
    height: 30px;
    line-height: 30px;
    font-size: 2.1em;
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 2;
}

#preview i.fa:before {
    color: #000;
    background-color: #fff;
    border-radius: 15px;
    width: 29px;
    height: 29px;
    display: block;
    text-align: center;
    line-height: 30px;
    box-shadow: 0 0 3px 1px #000;
    padding-left: 1px;
}

@media screen and (max-width: 991px) {
    #headerright .navigation {
        position: absolute;
        top: 100%;
        width: 100%;
        left: 0;
        height: auto;
        background-color: #fff;
    }
}

@media screen and (min-width: 768px) {

    .previous-orders .details {
        float: left;
    }

    .previous-orders .products {
        float: left;
    }

    .previous-orders .cost {
        float: right;
    }

    .previous-orders .orders-top .products {
        position: relative;
        padding-right: 45px;
    }

    .previous-orders .orders-top .products span {
        position: absolute;
        top: 0;
        right: 15px;
        width: 30px;
        text-align: center;
    }

    .previous-orders .products div {
        position: relative;
        padding-right: 30px;
    }

    .previous-orders .products div span {
        position: absolute;
        top: 0;
        right: 0px;
        width: 30px;
        text-align: center;
    }
}

@media screen and (max-width: 767px) {
    #headerright .accounts {
        text-align: center;
    }

    #headerright .log.browse {
        padding: 10px;
    }

    #headerright .log.browse.navopen {
        background-color: #004d94;
    }

    #headerright .log.browse.navopen a, #headerright .log.browse.navopen i {
        color: #fff;
    }

    #headerright .log, #headerright .cart {
        float: none;
        display: inline-block !important;
    }

    .sab {
        margin-top: 10px;
    }

    #delivery-options label {
        padding: 10px 105px 10px 34px;
    }

    #delivery-options label .rb {
        left: 10px;
    }

    #delivery-options label span.price {
        right: -25px;
    }

    #delivery-options label .details strong, #delivery-options label .details span {
        display: block;
    }

    .previous-orders .orders-top {
        display: none;
    }

    .previous-orders .orders-row .products {
        margin: 15px 0;
    }

    .previous-orders .orders-row .products div {
        position: relative;
        padding-right: 40px;
    }

    .previous-orders .orders-row .products div span {
        position: absolute;
        top: 0;
        right: 0;
        width: 30px;
        text-align: center;
    }

    .previous-orders.other .details {
        margin-bottom: 15px;
    }

    .previous-orders.other .products {
        width: 50%;
        padding: 0 15px 0 40px;
        position: relative;
        float: left;
        margin: 0;
    }

    .previous-orders.other .products div {
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        line-height: 40px;
    }

    .previous-orders.other .cost {
        width: 50%;
        float: left;
    }
}



@media screen and (max-width:520px) {
    .pay-opt h2 {
        float: none;
    }

    .pay-opt span.btn {
        float: none;
        width: 100%;
        text-align: center;
        padding: 0;
        margin-bottom: 10px;
    }

    .pay-opt .card-surround {
        padding: 10px 10px 10px 40px;
    }

    .pay-opt .card-surround .check {
        position: absolute;
        top: 10px;
        left: 10px;
        margin-top: 10px;
    }

    .pay-opt .card-surround .card-details, .pay-opt .card-surround .card-expiry {
        float: none;
        padding: 0;
    }
}

@media screen and (max-width:400px) {
    .basket .row.regular .col1 {
        padding: 0;
        min-height: 0;
    }

    .basket .row.regular .col1 img {
        display: none;
    }

    .basket .btn {
        max-width: 100%;
    }

    .basket .btn.grey {
        margin: 15px 0 0 0;
    }

}


@-ms-keyframes load4 {
    0%,100% {
        box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }

    12.5% {
        box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    25% {
        box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    37.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    50% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    62.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }

    75% {
        box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }

    87.5% {
        box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
}

@-o-keyframes load4 {
    0%,100% {
        box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }

    12.5% {
        box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    25% {
        box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    37.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    50% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    62.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }

    75% {
        box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }

    87.5% {
        box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
}

@-moz-keyframes load4 {
    0%,100% {
        box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }

    12.5% {
        box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    25% {
        box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    37.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    50% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    62.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }

    75% {
        box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }

    87.5% {
        box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
}

@-webkit-keyframes load4 {
    0%,100% {
        box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }

    12.5% {
        box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    25% {
        box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    37.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    50% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    62.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }

    75% {
        box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }

    87.5% {
        box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
}

@keyframes load4 {
    0%,100% {
        box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }

    12.5% {
        box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    25% {
        box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    37.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    50% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    62.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }

    75% {
        box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }

    87.5% {
        box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
}


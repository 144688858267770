﻿.galleries {
    margin-top: -140px;

    @include media('<=phone') {
        margin-top: 0;
    }
}

.gallery {
    h1 {
        top: 20%!important;
    }

    .internal__banner--text {
        top: 45%!important;
        max-width: 930px!important;
    }
}

.gallery__featured {
    padding: 0;
    margin-bottom: 40px;
}

.gallery__element {
    margin-bottom: 15px;
}

.gallery__image {
    position: relative;
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.4);
    border-radius: 2px;
    max-width: 280px;
    min-height: 180px;
}

.gal--1 {
    background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../img/store-gallery.jpg');
}

.gal--2 {
    background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../img/fish-gallery.jpg');
}

.gal--3 {
    background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../img/coral-gallery.jpg');
}

.gal--4 {
    background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../img/customer-gallery.jpg');
}

.gallery__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    text-align: center;

    h3 {
        color: $color-white;
        margin-bottom: 40px;
        font-size: 1.6em;
    }

    .btn {
        max-width: 140px;
        margin: auto;
    }
}

.gallery--tabs {
    max-width: 900px;
    min-height: 600px;
    margin: auto;

    @include media('<=phone') {
        min-height: 200px !important;
    }

    h2 {
        text-align: center;
        margin-bottom: 35px;
    }

    .bx-viewport {
        border-radius: 2px;
        box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.4);
        height: 600px !important;

        @include media('<=phone') {
            height: 200px !important;
        }
    }

    .bx-pager {
        display: none;
    }

    a.fa-chevron-left {
        left: -100px !important;

        @include media('<desktop','>tablet') {
            left: -40px !important;
        }

        @include media('<=tablet') {
            left: -25px !important;
        }
    }

    a.fa-chevron-right {
        right: -100px !important;

        @include media('<desktop','>tablet') {
            right: -40px !important;
        }

        @include media('<=tablet') {
            right: -25px !important;
        }
    }
}

.gallery--tabs.active {
    visibility: visible;
    opacity: 1;
    display: block !important;
}

.gallery--tabs.notactive {
    display: none;
}

.gallery__share {
    background: linear-gradient( rgba(225, 241, 254, 0.4), rgba(225, 241, 254, 0.4)), url('../img/socials-bg.png') no-repeat center;
    text-align: center;
    background-size: cover;
    padding: 40px;
    margin-top: 40px;
    h3 {
        font-size: 1.6em;
        margin-bottom: 20px;

        span {
            color: $color-orange;
        }
    }

    .section {
        max-width: 400px;
        margin: 30px auto 0;
    }
    .dw-xs-3 {
        width: 20%;
    }
    
}

.gall--text {
    display: block;
    max-width: 790px;
    color: $color-dark-grey;
    margin: auto;
}

.gall--social {
    a {
        background-color: $color-second;
        width: 50px;
        height: 50px;
        line-height: 50px;
        border-radius: 50px;
        color: $color-white;
        font-size: 1.3em;
        display: block;

        &:hover {
            background-color: $color-orange;
        }
    }
}

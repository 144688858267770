﻿.tiles {
    margin: 35px auto 15px;

    .btn {
        max-width: 120px;
        margin-top: 15px;
        font-weight: 600;
    }

    img {
        position: absolute;
        right: 0;
        bottom: 0;
    }

    .section {
        @include media('<=phone') {
            margin: 0;
        }

        .dw-md-4:nth-child(2n+1) {
            @include media('<=tablet') {
                clear: both;
            }
        }
    }
}

.tiles__link {
    display: block;
    min-height: 180px;
    position: relative;
    border-radius: 2px;
    background-color: $color-primary;
    margin-bottom: 30px;
    &:hover .btn {
        background-color: $color-white;
        border-color: $color-white;
        color: $color-orange;
    }
}

.tiles__inner {
    position: relative;
    z-index: 1;
    color: $color-white;
}

.tile__text {
    padding: 15px 20px;
}

.tiles--title {
    display: block;
    font-weight: 700;
    font-size: 1.8em;
    margin-bottom: 20px;

    @include media('<desktop','>tablet') {
        font-size: 1.5em;
    }

    @include media('<=tablet') {
        font-size: 1.5em;
    }
}

.tiles--description {
    display: block;
    width: 48%;

    @include media('<desktop','>tablet') {
        width: 52%;
        font-size: 0.85em;
    }
}

.tiles--description.first {
    width: 60%;

    @include media('<desktop','>tablet') {
        width: 70%;
    }
}